package medi.common.enm.boundx

// 패키지 규격
enum class EnumBoundxPackageStandard(val rowid:Int, val title:String){
    SMALL(1, "소"),
    MEDIUM(2, "중"),
    LARGE(3, "대"),
    SIX(4, "6L"),
    ELEVEN(5, "11L"),
    NINETEEN(6, "19L"),
    TEN_LITERS(7, "10L"),
    FORTY_EIGHT_LITERS(8, "48L"),
    SEVENTY_LITERS(9, "70L");
    companion object{
        operator fun invoke(i:Int) = values().find{it.rowid == i}?: error("없는 배송상태입니다.")
        operator fun invoke(i:String) = values().find{it.rowid.toString() == i}?: error("없는 배송상태입니다.")
    }
}
