package medi.common.entity.api.user.carrier

import MdlBeDeliveryFile
import medi.common.entity.api.user.model.MdlTransPort
import boundx.common.enm.EnumDeliveryCar
import boundx.common.enm.EnumDeliveryCarOption
import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum
import ein2b.core.entity.field.enum.enumList
import medi.common.enm.EnumApiAccessAllow
import medi.common.enm.EnumBeDeliveryStateTab
import medi.common.enm.EnumTimeCat
import medi.common.enm.boundx.EnumBoundxDeliveryPackageState
import medi.common.enm.boundx.EnumBoundxDeliveryState
import medi.common.enm.boundx.EnumBoundxPackageStandard
import medi.common.enm.boundx.EnumBoundxReturnPackageState
import medi.common.enm.db.EnumAuthorityCat
import medi.common.enm.db.EnumDeliveryState
import medi.common.enm.db.EnumPackageState
import medi.common.entity.api.user.common.EntUserApiPageMeta
import medi.common.entity.api.user.common.EntUserApiReq
import medi.common.vali.ValiCompanyDriverRowid
import medi.common.vali.ValiPage

//배차 리스트

class EntUserApiCarrierDeliveryReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/delivery/{userId}/{state}/{year}/{month}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH)
            .replace("{userId}", "@$userId")
            .replace("{state}", if(isDispatched) "1" else "0")
            .replace("{year}", year)
            .replace("{month}", month)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.CARRIER_DELIVERY_LIST)

    var userId = ""
    var isDispatched: Boolean by bool(::isDispatched) { default(false) }
    var selectedTab:EnumBeDeliveryStateTab by enum(::selectedTab)
    var year:String by string(::year) {
        default("오늘 날짜 기준 연도 계산", false)
    }
    var page:String by string(::page){
        validator(ValiPage)
        default("1",false)
    }
    var month:String by string(::month) {
        default("오늘 날짜 기준 월 계산", false)
    }
}

class EntUserApiCarrierDeliveryRes:eEntity(){
    class Meta: EntUserApiPageMeta(){
        var isDispatched: Boolean by bool(::isDispatched)
        var year:String by string(::year)
        var month:String by string(::month)
    }
    class DriverGroup: eEntity(true){
        class Driver: eEntity(true){
            var companyDriverRowid:String by string(::companyDriverRowid) // 1
            var name:String by string(::name) // 김세훈
            var phone:String by string(::phone)
        }
        var title:String by string(::title) // 1t/냉동
        var driverList: MutableList<Driver> by entityList(::driverList, DriverGroup::Driver){
            default(mutableListOf(), false)
        }
    }
    abstract class PackageCommon:eEntity(true){
        var invoiceNum:String by string(::invoiceNum)
        var itemPackage:String by string(::itemPackage)
        var itemMinTemp:String by string(::itemMinTemp) //x100
        var itemMaxTemp:String by string(::itemMaxTemp) //x100
    }
    abstract class Delivery: eEntity(true){
        var deliveryRowid:String by string(::deliveryRowid)
        var deliveryId:String by string(::deliveryId)
        var requestDate:String by string(::requestDate)
        var regDate:String by string(::regDate)
        var packerName:String by string(::packerName)
        var packerAddr:String by string(::packerAddr)
        var receiverName:String by string(::receiverName)
        var receiverAddr:String by string(::receiverAddr)
    }
    class CompleteDelivery:Delivery(){
        class Package:PackageCommon(){
            var receiverNameUrl:String by string(::receiverNameUrl){ default("", false) }
            var receiverSignUrl:String by string(::receiverSignUrl){ default("", false) }
            var receiveDate:String by string(::receiveDate){ default("", false) }
            var packageState: EnumPackageState by enum(::packageState)
        }
        var packerTel:String by string(::packerTel)
        var receiverTel:String by string(::receiverTel)
        var driverName:String by string(::driverName)
        var driverPhone:String by string(::driverPhone)
        var driverGroupName:String by string(::driverGroupName)
        var deliveryState: EnumDeliveryState by enum(::deliveryState)
        var packageList: MutableList<Package> by entityList(::packageList, ::Package){ default(mutableListOf(), false) }
    }
    class NoDispatchDelivery:Delivery() {
        class Package:PackageCommon()
        var packageList: MutableList<Package> by entityList(::packageList, ::Package){ default(mutableListOf(), false) }
    }
    abstract class BeDelivery:eEntity(true) {
        var deliveryRowid:String by string(::deliveryRowid)
        var requestDate:String by string(::requestDate)
        var requestUserId:String by string(::requestUserId)
        var deliveryDate:String by string(::deliveryDate)
        var regDate:String by string(::regDate)
        var memo:String by string(::memo)
        var json:String by string(::json)
        var senderAlias:String by string(::senderAlias)
        var senderName:String by string(::senderName)
        var senderAddr1:String by string(::senderAddr1)
        var senderAddr2:String by string(::senderAddr2)
        var senderPhone:String by string(::senderPhone)
        var receiverAlias:String by string(::receiverAlias)
        var receiverName:String by string(::receiverName)
        var receiverAddr1:String by string(::receiverAddr1)
        var receiverAddr2:String by string(::receiverAddr2)
        var receiverPhone:String by string(::receiverPhone)
        val requestDateTimeCat get() = EnumTimeCat.time(requestDate.split(' ')[1])
        var fileList:MutableList<MdlBeDeliveryFile> by entityList(::fileList,::MdlBeDeliveryFile)
        /** JSON으로 저장하여 Req.json에 저장하는 용도의 클래스 */
        class JSON: eEntity() {
            var carType: EnumDeliveryCar by enum(::carType)
            var carOptionList: MutableList<EnumDeliveryCarOption>
                    by enumList(::carOptionList) { default(mutableListOf()) } // 없는 경우 빈 리스트
        }

        companion object {
            fun getCarTitle(json:String):String {
                var res = ""
                eEntity.parse(JSON(), json)?.let { json ->
                    res += "${json.carType.title}"
                    val carOption = json.carOptionList.joinToString(", ") { it.title }
                    if(carOption.isNotBlank()) res += " (${carOption})"
                }
                return res
            }
        }
    }
    class NoDispatchBeDelivery:BeDelivery(){
        open class ReturnPackage:eEntity(true){
            var packageStandard: EnumBoundxPackageStandard by enum(::packageStandard)
            var memo:String by string(::memo)
            var content:String by string(::content)
            var json:String by string(::json)
        }
        class DeliveryPackage:ReturnPackage() {
            var invoiceNum:String by string(::invoiceNum)
        }
        var isMap:Boolean by bool(::isMap)
        var isAutoDispatched:Boolean by bool(::isAutoDispatched) // gogox 자동배차 여부. slabhcdriver2를 바라보는 배송건이라면 해당 값이 1이다.
        var companyName:String by string(::companyName)
        var deliveryPackageList: MutableList<DeliveryPackage> by entityList(::deliveryPackageList, ::DeliveryPackage)
        var returnPackageList: MutableList<ReturnPackage> by entityList(::returnPackageList, ::ReturnPackage)
    }
    class CompleteBeDelivery:BeDelivery(){
        abstract class Package:eEntity(true){
            var deliveryPackageRowid:String by string(::deliveryPackageRowid)
            var packageStandard: EnumBoundxPackageStandard by enum(::packageStandard)
            var memo:String by string(::memo)
            var json:String by string(::json)
            var content:String by string(::content)
            var receiveDate:String by string(::receiveDate){ default("", false) }
        }
        class ReturnPackage:Package() {
            var packageState: EnumBoundxReturnPackageState by enum(::packageState)
        }
        class DeliveryPackage:Package() {
            var invoiceNum:String by string(::invoiceNum)
            var packageState: EnumBoundxDeliveryPackageState by enum(::packageState)
            var receiverName:String by string(::receiverName){ default("", false) }
            var receiverSignUrl:String by string(::receiverSignUrl){ default("", false) }
        }
        var companyName:String by string(::companyName)
        var driverName:String by string(::driverName){ default("", false) }
        var driverPhone:String by string(::driverPhone){ default("", false) }
        var driverGroupName:String by string(::driverGroupName){ default("", false) }
        var isExternalDriver: Boolean by bool(::isExternalDriver)
        var isExternalLogistics: Boolean by bool(::isExternalLogistics)
        var deliveryState: EnumBoundxDeliveryState by enum(::deliveryState)
        var deliveryPackageList: MutableList<DeliveryPackage> by entityList(::deliveryPackageList, ::DeliveryPackage)
        var returnPackageList: MutableList<ReturnPackage> by entityList(::returnPackageList, ::ReturnPackage)
        var isLtGoGoModalOpen: Boolean by bool(::isLtGoGoModalOpen)
        var gogoxDeliveryState: String by string(::gogoxDeliveryState)
    }
    var noDispatchDeliveryList: MutableList<NoDispatchDelivery> by entityList(::noDispatchDeliveryList, ::NoDispatchDelivery){ default(mutableListOf(), false) }
    var completeDeliveryList: MutableList<CompleteDelivery> by entityList(::completeDeliveryList, ::CompleteDelivery){ default(mutableListOf(), false) }
    var noDispatchBeDeliveryList: MutableList<NoDispatchBeDelivery> by entityList(::noDispatchBeDeliveryList, ::NoDispatchBeDelivery){ default(mutableListOf(), false) }
    var completeBeDeliveryList: MutableList<CompleteBeDelivery> by entityList(::completeBeDeliveryList, ::CompleteBeDelivery){ default(mutableListOf(), false) }
    var meta: Meta by entity(::meta, ::Meta)
    var noDispatchCnt:String by string(::noDispatchCnt)
    var completeCnt:String by string(::completeCnt)
    var driverGroupList: MutableList<DriverGroup> by entityList(::driverGroupList, ::DriverGroup)
}

//배차 등록
class EntUserApiCarrierDeliveryWpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/delivery/wp"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON
        else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var deliveryRowids: MutableList<String> by stringList(::deliveryRowids)
    var beDeliveryRowids: MutableList<String> by stringList(::beDeliveryRowids)
    var companyDriverRowid:String by string(::companyDriverRowid){ validator(ValiCompanyDriverRowid) }
}

class EntUserApiCarrierDeliveryWpRes:eEntity()

//배송 취소
class EntUserApiCarrierBoundxDeliveryCancelWpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/delivery/cancel/wp"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON
        else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    class PackageInfo:eEntity(true) {
        var rowid:String by string(::rowid)
        var isComplete: Boolean by bool(::isComplete)
        var completeDate:String by string(::completeDate)
    }
    var deliveryRowid:String by string(::deliveryRowid)
    var packages: MutableList<PackageInfo> by entityList(::packages,::PackageInfo)
    var returnPackages: MutableList<PackageInfo> by entityList(::returnPackages,::PackageInfo)
}

class EntUserApiCarrierBoundxDeliveryCancelWpRes:eEntity()

// 파일 리스트
class EntUserApiCarrierBoundxDeliveryFileReq:EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/delivery/file/list"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =  if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var deliveryRowid:String by string(::deliveryRowid)
}
class EntUserApiCarrierBoundxDeliveryFileRes:eEntity() {
    var fileList:MutableList<MdlBeDeliveryFile> by entityList(::fileList,::MdlBeDeliveryFile)// 화물 파일 리스트
}
// 파일 등록
class EntUserApiCarrierBoundxDeliveryFileWpReq:EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/delivery/file/wp"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =  if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()

    var deliveryRowid:String by string(::deliveryRowid)
}
class EntUserApiCarrierBoundxDeliveryFileWpRes:eEntity() {
    var fileList:MutableList<MdlBeDeliveryFile> by entityList(::fileList,::MdlBeDeliveryFile)// 화물 파일 리스트
}
// 파일 삭제
class EntUserApiCarrierBoundxDeliveryFileDpReq:EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/delivery/file/dp"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =  if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var deliveryRowid:String by string(::deliveryRowid)
    var fileId:String by string(::fileId)
}
class EntUserApiCarrierBoundxDeliveryFileDpRes:eEntity() {
    var fileList:MutableList<MdlBeDeliveryFile> by entityList(::fileList,::MdlBeDeliveryFile)// 화물 파일 리스트
}
//배송 회수 거절 및 완료
class EntUserApiCarrierBoundxDeliveryCompleteWpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/delivery/complete/wp"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON
        else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    class PackageInfo:eEntity(true) {
        var rowid:String by string(::rowid)
        var isComplete: Boolean by bool(::isComplete)
        var completeDate:String by string(::completeDate)
    }
    var deliveryRowid:String by string(::deliveryRowid)
    var packages: MutableList<PackageInfo> by entityList(::packages, ::PackageInfo)
    var returnPackages: MutableList<PackageInfo> by entityList(::returnPackages, ::PackageInfo)
}

class EntUserApiCarrierBoundxDeliveryCompleteWpRes:eEntity()


class EntUserApiCarrierDeliveryDownloadReq:EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/delivery/download"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  = if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var isDispatched: Boolean by bool(::isDispatched) { default(false) }
    var year:String by string(::year) {
        default("오늘 날짜 기준 연도 계산", false)
    }
    var month:String by string(::month) {
        default("오늘 날짜 기준 월 계산", false)
    }
    var day:String by string(::day) {
        default("오늘 날짜 기준 일 계산", false)
    }
}
class EntUserApiCarrierDeliveryDownloadRes:eEntity(){
    var url:String by string(::url)
}

//BX 송장 인쇄
class EntUserApiCarrierInvoicePrintReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/invoice/print"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON
        else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var deliveryRowid: String by string(::deliveryRowid)
}

class EntUserApiCarrierInvoicePrintRes:eEntity() {
    class Invoice:eEntity() {
        var invoiceNum: String by string(::invoiceNum)
        var content: String by string(::content)
        var memo: String by string(::memo)
        var json: String by string(::json)
    }

    var senderAddr1: String by string(::senderAddr1)
    var senderAddr2: String by string(::senderAddr2)
    var senderName: String by string(::senderName)
    var senderPhone: String by string(::senderPhone)
    var receiverAddr1: String by string(::receiverAddr1)
    var receiverAddr2: String by string(::receiverAddr2)
    var receiverName: String by string(::receiverName)
    var receiverPhone: String by string(::receiverPhone)

    var invoiceList: MutableList<Invoice> by entityList(::invoiceList, ::Invoice)
}

class EntUserApiCarrierDeliveryEReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/delivery/e"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  = if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.CARRIER_DELIVERY_LIST)

    var userId = ""
    var beDeliveryRowid: String by string(::beDeliveryRowid)
}

class EntUserApiCarrierDeliveryDpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/delivery/dp"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  = if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.CARRIER_DELIVERY_LIST)

    var beDeliveryRowid: String by string(::beDeliveryRowid)
}

class EntUserApiCarrierDeliveryDpRes:eEntity()


class EntUserApiCarrierDeliveryMapParse: eEntity(){
    var rs:String by string(::rs)
}

class EntUserApiCarrierDeliveryMapReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/delivery/map"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON
        else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var deliveryRowidList:MutableList<String> by stringList(::deliveryRowidList)
}

class EntUserApiCarrierDeliveryMapRes:eEntity() {
    class Delivery:eEntity(true) {
        var deliveryRowid: String by string(::deliveryRowid)
        var senderLat: String by string(::senderLat)
        var senderLng: String by string(::senderLng)
        var receiverLat: String by string(::receiverLat)
        var receiverLng: String by string(::receiverLng)
        var requestDate: String by string(::requestDate)
        var deliveryDate: String by string(::deliveryDate)
    }
    var deliveryList: MutableList<Delivery> by entityList(::deliveryList, ::Delivery)
}

// transportRowid 리스트
class EntUserApiCarrierTransportRowidListReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/tranportRowid/list"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON
        else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var beDeliveryRowid:String by string(::beDeliveryRowid)
}
class EntUserApiCarrierTransportRowidListRes:eEntity() {
    var transportRowidList: MutableList<MdlTransPort> by entityList(::transportRowidList, ::MdlTransPort)
}


