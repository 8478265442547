package home

import Factory
import medi.common.app.App
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import medi.common.enm.db.EnumAuthorityCat
import medi.common.enm.db.EnumRoleCat
import medi.common.entity.api.user.common.EntUserApiHomeRes
import medi.user.app.ApiUser
import medi.user.app.ModulePathUser
import medi.user.app.RouterKeyUser.CARRIER_DRIVER_GROUP_LIST
import medi.user.app.RouterKeyUser.DISPATCH_LIST
import medi.user.app.RouterKeyUser.NEW_SUB_ACCOUNT2
import medi.user.app.RouterKeyUser.PACKAGE_LIST
import medi.user.app.RouterKeyUser.PACKER_CARRIER_LIST
import medi.user.app.RouterKeyUser.PACKER_DRIVER_GROUP_LIST
import medi.user.app.RouterKeyUser.SHIPPER_DELIVERY_REQUEST_ADD
import medi.user.app.RouterKeyUser.SHIPPER_DRIVER_GROUP_LIST
import medi.user.app.RouterKeyUser.SHIPPER_PACKER_LIST
import org.w3c.dom.HTMLElement

private val factory = Factory.htmlUrl("home")

private enum class K{
    shipperLink1, shipperLink2, shipperLink3, packerLink1, packerLink2, packerLink3, packerLink4, carrierLink1, carrierLink2;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}


suspend fun HomeView() = eView(factory){ rootView->

    rootView.sub(K.shipperLink1){
      it.html = "포장사 관리"
    }
    rootView.sub(K.shipperLink2){
      it.html = "기사 그룹 관리"
    }
    rootView.sub(K.shipperLink3){
      it.html = "배송 의뢰"
    }

    rootView.sub(K.packerLink1){
      it.html = "서브 계정 관리"
    }
    rootView.sub(K.packerLink2){
      it.html = "기사 그룹 관리"
    }
    rootView.sub(K.packerLink3){
      it.html = "배송사 관리"
    }
    rootView.sub(K.packerLink4){
      it.html = "포장 리스트"
    }

    rootView.sub(K.carrierLink1){
      it.html = "기사 그룹 관리"
    }
    rootView.sub(K.carrierLink2){
      it.html = "배차 리스트"
    }

    rootView.addEntityHook(EntUserApiHomeRes::class, object:eEntityHook<HTMLElement, EntUserApiHomeRes>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntUserApiHomeRes) {
            view.sub(K.shipperLink1){
                val result = ApiUser.member.authorityCatList.find { auth -> auth == EnumAuthorityCat.SHIPPER_PACKER_MANAGEMENT }

                if(result != null) {
                    it.click = {_,_->
                        eLaunch {
                            App.goUrl(ModulePathUser.M_SHIPPER, SHIPPER_PACKER_LIST)
                        }
                    }
                    it.className = "step-link"
                }else {
                    it.className = ""
                }
            }
            view.sub(K.shipperLink2){
                val result = ApiUser.member.authorityCatList.find { auth -> auth == EnumAuthorityCat.SHIPPER_DRIVER_MANAGEMENT }
                if(result != null) {
                    it.click = {_,_->
                        eLaunch {
                            App.goUrl(ModulePathUser.M_SHIPPER, SHIPPER_DRIVER_GROUP_LIST)
                        }
                    }
                    it.className = "step-link"
                }else {
                    it.className = ""
                }

            }
            view.sub(K.shipperLink3){
                val result = ApiUser.member.authorityCatList.find { auth -> auth == EnumAuthorityCat.SHIPPER_DELIVERY_REQUEST }
                if(result != null) {
                    it.click = {_,_->
                        eLaunch {
                            App.goUrl(ModulePathUser.M_SHIPPER, SHIPPER_DELIVERY_REQUEST_ADD)
                        }
                    }
                    it.className = "step-link"
                }else {
                    it.className = ""
                }
            }
            view.sub(K.packerLink1){
                val result = ApiUser.member.roleCat == EnumRoleCat.MASTER
                if(result) {
                    it.click = {_,_->
                        eLaunch {
                            App.goUrl(ModulePathUser.M_MEMBER, NEW_SUB_ACCOUNT2)
                        }
                    }
                    it.className = "step-link"
                }else {
                    it.className = ""
                }
            }
            view.sub(K.packerLink2){
                val result = ApiUser.member.authorityCatList.find { auth -> auth == EnumAuthorityCat.PACKER_DRIVER_MANAGEMENT }
                if(result != null) {
                    it.click = {_,_->
                        eLaunch {
                            App.goUrl(ModulePathUser.M_PACKER, PACKER_DRIVER_GROUP_LIST)
                        }
                    }
                    it.className = "step-link"
                }else {
                    it.className = ""
                }
            }
            view.sub(K.packerLink3){
                val result = ApiUser.member.authorityCatList.find { auth -> auth == EnumAuthorityCat.PACKER_CARRIER_MANAGEMENT }
                if(result != null) {
                    it.click = {_,_->
                        eLaunch {
                            App.goUrl(ModulePathUser.M_PACKER, PACKER_CARRIER_LIST)
                        }
                    }
                    it.className = "step-link"
                }else {
                    it.className = ""
                }
            }
            view.sub(K.packerLink4){
                val result = ApiUser.member.authorityCatList.find { auth -> auth == EnumAuthorityCat.PACKER_PACKAGE_LIST }
                if(result != null) {
                    it.click = {_,_->
                        eLaunch {
                            App.goUrl(ModulePathUser.M_PACKER, PACKAGE_LIST)
                        }
                    }
                    it.className = "step-link"
                }else {
                    it.className = ""
                }
            }
            view.sub(K.carrierLink1){
                val result = ApiUser.member.authorityCatList.find { auth -> auth == EnumAuthorityCat.CARRIER_DRIVER_MANAGEMENT }
                if(result != null) {
                    it.click = {_,_->
                        eLaunch {
                            App.goUrl(ModulePathUser.M_CARRIER, CARRIER_DRIVER_GROUP_LIST)
                        }
                    }
                    it.className = "step-link"
                }else {
                    it.className = ""
                }
            }
            view.sub(K.carrierLink2){
                val result = ApiUser.member.authorityCatList.find { auth -> auth == EnumAuthorityCat.CARRIER_DELIVERY_LIST }
                if(result != null) {
                    it.click = {_,_->
                        eLaunch {
                            App.goUrl(ModulePathUser.M_CARRIER, DISPATCH_LIST)
                        }
                    }
                    it.className = "step-link"
                }else {
                    it.className = ""
                }
            }
        }
    })
}
