package medi.common.vali

import ein2b.core.validation.eRuleVali

val ValiClientSecretKey = eRuleVali{
    Case{
        Rule{"$it".trim()}
    }
}
//gogox remark
val ValiRemarkLength = eRuleVali{
    Case {
        Rule{"$it"}
        MaxLength(256, "256자 이내로 입력해 주세요.")
    }
}
val ValiGoodsRemarkLength = eRuleVali{
    Case {
        Rule{"$it"}
        MaxLength(255, "255자 이내로 입력해 주세요.")
    }
}
