package medi.user.entity.dispatch

import MdlBeDeliveryFile
import boundx.common.enm.EnumDeliveryCar
import boundx.common.enm.EnumDeliveryCarOption
import boundx.common.enm.EnumTempRequiredType
import boundx.common.enm.EnumTempType
import ein2b.core.core.err
import ein2b.core.date.eDateT
import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum
import ein2b.core.view.eView
import ein2b.js.js.eDate
import medi.common.enm.EnumBeDeliveryStateTab
import medi.common.enm.EnumTimeCat
import medi.common.enm.boundx.EnumBoundxDeliveryPackageState
import medi.common.enm.boundx.EnumBoundxPackageStandard
import medi.common.enm.boundx.EnumBoundxReturnPackageState
import medi.common.enm.db.EnumPackageState
import medi.common.enm.lt.EnumOrderStatusLt
import medi.common.entity.api.user.carrier.EntUserApiCarrierDeliveryRes
import medi.common.entity.api.user.lt.EntUserApiGogoXRes
import medi.user.app.AppUser
import org.w3c.dom.HTMLElement
import kotlin.js.Date

class EntClientDispatchDetailCompany:eEntity(true){
    var name:String by string(::name)
    var addr1:String by string(::addr1)
    var addr2:String by string(::addr2)
    var tel:String by string(::tel)
}

class EntClientDispatchV(target: EntUserApiCarrierDeliveryRes.CompleteDelivery):eEntity() {
    class Package:eEntity(true){
        var title:String by string(::title)
        var itemPackage:String by string(::itemPackage)
        var state: EnumPackageState by enum(::state)
        var receiverNameUrl:String by string(::receiverNameUrl)
        var receiverSignUrl:String by string(::receiverSignUrl)
        var receiveDate:String by string(::receiveDate)
    }
    var sender: EntClientDispatchDetailCompany by entity(::sender, ::EntClientDispatchDetailCompany){
        default(
            EntClientDispatchDetailCompany().also { to ->
                to.name = target.packerName
                to.addr1 = target.packerAddr
                to.addr2 = "없음"
                to.tel = target.packerTel
            }
        )
    }
    var receiver: EntClientDispatchDetailCompany by entity(::receiver, ::EntClientDispatchDetailCompany){
        default(
            EntClientDispatchDetailCompany().also { to ->
                to.name = target.receiverName
                to.addr1 = target.receiverAddr
                to.addr2 = "없음"
                to.tel = target.receiverTel
            }
        )
    }
    var packageList: MutableList<Package> by entityList(::packageList, EntClientDispatchV::Package){
        default(
            target.packageList.mapIndexed { idx, from ->
                Package().also { to ->
                    to.title = "패키지 ${idx+1} (송장번호: ${from.invoiceNum})"
                    to.itemPackage = "${from.itemPackage} (${from.itemMinTemp} ~ ${from.itemMaxTemp})"
                    to.state = from.packageState
                    to.receiveDate = "${from.receiveDate} 배송 완료"
                    to.receiverNameUrl = from.receiverNameUrl
                    to.receiverSignUrl = from.receiverSignUrl
                }
            }.toMutableList()
        )
    }
    var driverName:String by string(::driverName){ default(target.driverName) }
    var driverPhone:String by string(::driverPhone){ default(if(target.driverPhone.isEmpty()) "" else "(연락처: ${target.driverPhone})") }
    var driverGroupName:String by string(::driverGroupName){ default(target.driverGroupName) }
}

/*배송 상세 모달용*/
class EntClientDispatchBeV(target: EntUserApiCarrierDeliveryRes.CompleteBeDelivery):eEntity() {
    class Package:eEntity(true){
        var state: EnumBoundxDeliveryPackageState by enum(::state)
        var title:String by string(::title)
        var contents:String by string(::contents)
        var standard:String by string(::standard)
        var memo:String by string(::memo)
        var receiverName:String by string(::receiverName)
        var receiverSignUrl:String by string(::receiverSignUrl)
        var receiveDate:String by string(::receiveDate)
    }
    class ReturnPackage:eEntity(true){
        var state: EnumBoundxReturnPackageState by enum(::state)
        var title:String by string(::title)
        var contents:String by string(::contents)
        var standard:String by string(::standard)
        var memo:String by string(::memo)
        var receiveDate:String by string(::receiveDate)
    }
    var sender: EntClientDispatchDetailCompany by entity(::sender, ::EntClientDispatchDetailCompany){
        default(
            EntClientDispatchDetailCompany().also { to ->
                to.name = target.senderName
                to.addr1 = target.senderAddr1
                to.addr2 = target.senderAddr2.ifBlank { "없음" }
                to.tel = target.senderPhone
            }
        )
    }
    var receiver: EntClientDispatchDetailCompany by entity(::receiver, ::EntClientDispatchDetailCompany){
        default(
            EntClientDispatchDetailCompany().also { to ->
                to.name = target.receiverName
                to.addr1 = target.receiverAddr1
                to.addr2 = target.receiverAddr2.ifBlank { "없음" }
                to.tel = target.receiverPhone
            }
        )
    }
    var packageList: MutableList<Package> by entityList(::packageList, ::Package){
        default(
            target.deliveryPackageList.mapIndexed { idx, from ->
                Package().also { to ->
                    to.state = from.packageState
                    to.title = "패키지 ${idx+1} (송장번호: ${from.invoiceNum})"
                    to.contents = from.content
                    to.standard = from.packageStandard.title
                    to.memo = EntClientDispatchManageBeV.Package.JSON.getMemo(from.memo, from.json)
                    to.receiveDate = if(from.receiveDate.isBlank()) "" else "${from.receiveDate} 배송 완료"
                    to.receiverName = from.receiverName
                    to.receiverSignUrl = from.receiverSignUrl
                }
            }.toMutableList()
        )
    }
    var returnPackageList: MutableList<ReturnPackage> by entityList(::returnPackageList, ::ReturnPackage){
        default(
            target.returnPackageList.mapIndexed { idx, from ->
                ReturnPackage().also { to ->
                    to.state = from.packageState
                    to.title = "회수 패키지 ${idx+1}"
                    to.contents = from.content
                    to.standard = "규격: ${from.packageStandard.title}"
                    to.memo = EntClientDispatchManageBeV.Package.JSON.getMemo(from.memo, from.json)
                    to.receiveDate = if(from.receiveDate.isBlank()) "" else "${from.receiveDate} 회수 완료"
                }
            }.toMutableList()
        )
    }
    var driverName:String by string(::driverName){ default(if(target.isExternalDriver) "외부 기사에게 위탁" else if(target.isExternalLogistics) "외부 택배사 사용" else  target.driverName) }
    var driverPhone:String by string(::driverPhone){ default(if(target.driverPhone.isEmpty()) "" else "(${target.driverPhone})") }
    var driverGroupName:String by string(::driverGroupName){ default(target.driverGroupName) }
    var isExternalDriver: Boolean by bool(::isExternalDriver){ default(target.isExternalDriver) }
    var isExternalLogistics: Boolean by bool(::isExternalLogistics){ default(target.isExternalLogistics) }
    var beDeliveryRowid:String by string(::beDeliveryRowid){default(target.deliveryRowid)}
}
/*파일 관리 모달용*/
interface iEntClientFile {
    var beDeliveryRowid:String
    var fileList:MutableList<MdlBeDeliveryFile>
}
class EntClientFile(target: iEntClientFile):eEntity() {
    var beDeliveryRowid = target.beDeliveryRowid
    var fileList = target.fileList
}
class EntClientBeNoDispatchBeFile(target: EntUserApiCarrierDeliveryRes.NoDispatchBeDelivery):iEntClientFile, eEntity() {
    var sender: EntClientDispatchDetailCompany by entity(::sender, ::EntClientDispatchDetailCompany){
        default(
            EntClientDispatchDetailCompany().also { to ->
                to.name = target.senderName
                to.addr1 = target.senderAddr1
                to.addr2 = target.senderAddr2.ifBlank { "없음" }
                to.tel = target.senderPhone
            }
        )
    }
    var receiver: EntClientDispatchDetailCompany by entity(::receiver, ::EntClientDispatchDetailCompany){
        default(
            EntClientDispatchDetailCompany().also { to ->
                to.name = target.receiverName
                to.addr1 = target.receiverAddr1
                to.addr2 = target.receiverAddr2.ifBlank { "없음" }
                to.tel = target.receiverPhone
            }
        )
    }
    override var beDeliveryRowid:String by string(::beDeliveryRowid){default(target.deliveryRowid)}
    override var fileList: MutableList<MdlBeDeliveryFile> by entityList(::fileList, ::MdlBeDeliveryFile){default(target.fileList)}
}
class EntClientBeCompleteBeFile(target: EntUserApiCarrierDeliveryRes.CompleteBeDelivery):iEntClientFile, eEntity() {
    var sender: EntClientDispatchDetailCompany by entity(::sender, ::EntClientDispatchDetailCompany){
        default(
            EntClientDispatchDetailCompany().also { to ->
                to.name = target.senderName
                to.addr1 = target.senderAddr1
                to.addr2 = target.senderAddr2.ifBlank { "없음" }
                to.tel = target.senderPhone
            }
        )
    }
    var receiver: EntClientDispatchDetailCompany by entity(::receiver, ::EntClientDispatchDetailCompany){
        default(
            EntClientDispatchDetailCompany().also { to ->
                to.name = target.receiverName
                to.addr1 = target.receiverAddr1
                to.addr2 = target.receiverAddr2.ifBlank { "없음" }
                to.tel = target.receiverPhone
            }
        )
    }
    override var beDeliveryRowid:String by string(::beDeliveryRowid){default(target.deliveryRowid)}
    override var fileList: MutableList<MdlBeDeliveryFile> by entityList(::fileList, ::MdlBeDeliveryFile){default(target.fileList)}
}
/*gogox 모달용*/
object GogoX {
    object Vehicle {
        const val MOTORCYCLENONE = "motorcycle.None"
        const val DAMASNONE = "damas.None"
        const val TON1NONE = "ton1.None"
        const val TON1NLIFT = "ton1.Lift"
    }
    object Standard {
        const val SMALLBOX = "smallbox"
        const val BIGBOX = "bigbox"
        const val GENERAL_FREIGHT = "general_freight"
    }
}
class EntClientBeNoDispatchGogoX:eEntity() {
    class GogoxForm {
        var view:eView<HTMLElement>? = null
        class Address {
            var name = ""
            var tel = ""
            var addr1 = ""
            var addr2 = ""
        }
        class Goods {
            var view:eView<HTMLElement>? = null
            var type = ""
            var qty = ""
            var remark = ""
        }
        class Vehicle{
            var name1 = ""
            var name2 = ""
        }
        var vehicleList = mutableListOf(Vehicle()) // gogox에서 받아오는 차량 리스트
        var selectedVehicle = ""
        var apoointmentAtDate = ""
        var apoointmentAtTime = ""
        var fromAddress = Address()
        var toAddress = Address()
        var requestAtDate = ""
        var requestAtTime = ""
        var isRequestAtWrapperDisplayNone = true
        var isDescriptionWrapperDisplayNone = true
        var description = ""
        var goodsItemList = mutableListOf<String>()
        var goodsList = mutableListOf(Goods())
        fun addGoods(){ goodsList.add(Goods()) }
        fun deleteGoods(item:Goods){ goodsList.remove(item) }
        var remark = ""
    }
    class PriceRes {
        var result = ""
        var contents = ""
        var amount= ""
        val isFail get() = result != "success"
        val isSuccess get() = result == "success"
    }
    class OrderSubmitRes {
        var result = ""
        var contents = ""
        var amount= ""
        var orderId = ""
        val isFail get() = result != "success"
    }
    var deliveryRequestDateTitle = ""
    var deliveryRequestDate = "" //픽업 예정일
    var deliveryArrivalDate = "" //도착 예정일
    var regDate = "" //배송 등록일
    var beDeliveryRowid = ""
    var deliveryCarTypeJson = ""
    var deliveryCarTitle = ""
    var deliverySenderName = ""
    var deliverySenderAddr1 = ""
    var deliverySenderAddr2 = ""
    val deliverySenderAddr get() = if(deliverySenderAddr2.isNotBlank()) "$deliverySenderAddr1, $deliverySenderAddr2" else deliverySenderAddr1
    var deliverySenderTel = ""
    var deliveryReceiverName = ""
    var deliveryRecevierAddr1 = ""
    var deliveryRecevierAddr2 = ""
    val deliveryReceiverAddr get() = if(deliveryRecevierAddr2.isNotBlank()) "$deliveryRecevierAddr1, $deliveryRecevierAddr2" else deliveryRecevierAddr1
    var deliveryPackageList = mutableListOf<EntUserApiCarrierDeliveryRes.NoDispatchBeDelivery.DeliveryPackage>()
    var deliveryCompanyName = ""
    var deliveryRegDate = ""
    var deliveryContent = ""
    var memo = ""
    var deliveryReceiverTel = ""
    var formList = mutableListOf<GogoxForm>()
    var isPriceSearch = false //가격 조회 여부
    var isOrderSubmit = false //주문 제출 여부
    var isOrderSearch = false //주문 조회 여부
    var priceResult = PriceRes() //가격 조회 결과
    fun priceResultInit() {
        priceResult = PriceRes()
    }
    var orderSubmitResult = OrderSubmitRes() //주문 제출 결과
    var orderStatus = EnumOrderStatusLt.PENDING //주문 상태
    var searchDate = "" //주문 조회 일시
    var driverName = ""
    var pickupDate = ""
    var cancelDate = "" // 주문 취소 일시
    var arrialDate = "" // 주문 도착 일시
    var photo = ""
    var isAutoDispatched = false
    fun setDataFrom(target: EntUserApiCarrierDeliveryRes.NoDispatchBeDelivery) {
        deliveryRequestDate = target.requestDate
        deliveryRequestDateTitle = """${eDateT.part("m-d", target.requestDate)} ${
            when (target.requestDateTimeCat) {
                EnumTimeCat.ETC -> eDateT.part("H:i", target.requestDate)
                EnumTimeCat.IMMEDIATELY -> "즉시 배차 - ${eDateT.part("H:i", target.regDate)}"
                else -> target.requestDateTimeCat.title
            }
        } 픽업"""
        deliveryArrivalDate = target.deliveryDate
        regDate = target.regDate
        beDeliveryRowid = target.deliveryRowid
        deliveryCarTypeJson = target.json
        deliveryCarTitle = EntUserApiCarrierDeliveryRes.BeDelivery.getCarTitle(target.json)
        deliverySenderName = target.senderAlias.ifBlank { target.senderName }
        deliverySenderAddr1 = target.senderAddr1
        deliverySenderAddr2 = target.senderAddr2
        deliverySenderTel = target.senderPhone
        deliveryReceiverName = target.receiverAlias.ifBlank { target.receiverName }
        deliveryRecevierAddr1 = target.receiverAddr1
        deliveryRecevierAddr2 = target.receiverAddr2
        deliveryReceiverTel = target.receiverPhone
        deliveryPackageList = target.deliveryPackageList
        deliveryCompanyName = target.companyName
        deliveryRegDate = target.regDate
        memo = target.memo
        deliveryContent = getContent(deliveryCarTitle, beDeliveryDeliveryDate(target.deliveryDate), target.memo)
        isAutoDispatched = target.isAutoDispatched
    }
    fun setDataFrom(target: EntUserApiGogoXRes) {
        formList = mutableListOf(GogoxForm().also{form ->
            form.vehicleList = target.vehicleCodeMap.vehicleCodeMap.map { vehicle ->
                GogoxForm.Vehicle().also { to ->
                    to.name1 = vehicle.vehicle.vehicleName1
                    to.name2 = vehicle.vehicle.vehicleName2
                }
            }.toMutableList()
            form.goodsItemList = target.vehicleCodeMap.vehicleCodeMap.flatMap { vehicle ->
                vehicle.codeList.flatMap { listOf(it.code.codeName2) }
            }.distinct().toMutableList()
            if (!target.ltGogoxRequest.isEmpty) {
                val req = target.ltGogoxRequest.orderRequest
                form.selectedVehicle = req.vehicle
                form.apoointmentAtDate = eDateT.part("Y-m-d",AppUser.toYMDHHMMSS(req.appointment_at))
                form.apoointmentAtTime = eDateT.part("H:i",AppUser.toYMDHHMMSS(req.appointment_at))
                form.requestAtDate = if(req.request_at.isNotBlank()) {
                    form.isRequestAtWrapperDisplayNone = false
                    eDateT.part("Y-m-d",AppUser.toYMDHHMMSS(req.request_at))
                } else ""
                form.requestAtTime = if(req.request_at.isNotBlank()) eDateT.part("H:i",AppUser.toYMDHHMMSS(req.request_at)) else ""
                form.description = req.description
                if(form.description.isNotBlank()) form.isDescriptionWrapperDisplayNone = false
                form.fromAddress = GogoxForm.Address().also { to ->
                    to.name = req.from.name
                    to.tel = req.from.mobile
                    to.addr1 = req.from.addr1
                    to.addr2 = req.from.addr2
                }
                form.toAddress = GogoxForm.Address().also { to ->
                    to.name = req.to.name
                    to.tel = req.to.mobile
                    to.addr1 = req.to.addr1
                    to.addr2 = req.to.addr2
                }
                form.goodsList = req.goods.map { goods ->
                    GogoxForm.Goods().also { to ->
                        to.type = goods.type
                        to.qty = goods.qty.toString()
                        to.remark = goods.remark
                    }
                }.toMutableList()
                form.remark = req.remark
                priceResult.result = "success"
            } else {
                if(isAutoDispatched) {
                    /** gogox 자동맵핑 로직*/

                    val remarkList = mutableListOf<String>("")
                    /** 비히클 자동 맵핑*/
                    val (vehicle,vehicleRemark) = getGogoXVehicleAndRemark(deliveryCarTypeJson)
                    remarkList.add(vehicleRemark)
                    form.selectedVehicle = vehicle
                    /** 픽업 예정일 자동 맵핑*/
                    val (appointAtDate,appointAtRemark)= getGogoXAppointAtAndRemark()
                    remarkList.add(appointAtRemark)
                    form.apoointmentAtDate = appointAtDate.split(" ")[0]
                    form.apoointmentAtTime = appointAtDate.split(" ")[1]
                    /** 배송사 전달 사항 맵핑*/
                    remarkList.add(memo)
                    form.remark = remarkList.filter { it.isNotBlank() }.joinToString(" / ")
                    /** 도착 예정일 자동 맵핑*/
                    val (waypointRequestAt, waypointDescription) = getGogoXArrivalDateAndRemark()
                    if(deliveryArrivalDate.isNotBlank()) {
                        form.requestAtDate = waypointRequestAt.split(" ")[0]
                        form.requestAtTime = waypointRequestAt.split(" ")[1]
                        form.description = waypointDescription
                        form.isRequestAtWrapperDisplayNone = false
                        if(waypointDescription.isNotBlank()) form.isDescriptionWrapperDisplayNone = false
                    }
                    /** 주소 자동 맵핑*/
                    form.fromAddress = GogoxForm.Address().also { to ->
                        to.name = deliverySenderName
                        to.tel = deliverySenderTel
                        to.addr1 = deliverySenderAddr1
                        to.addr2 = deliverySenderAddr2
                    }
                    form.toAddress = GogoxForm.Address().also { to ->
                        to.name = deliveryReceiverName
                        to.tel = deliveryReceiverTel
                        to.addr1 = deliveryRecevierAddr1
                        to.addr2 = deliveryRecevierAddr2
                    }
                    /** 패키지 자동 맵핑*/
                    form.goodsList = deliveryPackageList.map { pkg ->
                        GogoxForm.Goods().also { to ->
                            to.type = when {
                                EnumDeliveryCar.QUICK.name in deliveryCarTypeJson || EnumDeliveryCar.DAMAS.name in deliveryCarTypeJson || vehicle == GogoX.Vehicle.TON1NONE -> when(pkg.packageStandard) {
                                    EnumBoundxPackageStandard.SMALL,EnumBoundxPackageStandard.MEDIUM -> GogoX.Standard.SMALLBOX
                                    EnumBoundxPackageStandard.LARGE -> GogoX.Standard.BIGBOX
                                    else -> err("패키지 규격이 없습니다.")
                                }
                                vehicle == GogoX.Vehicle.TON1NLIFT -> GogoX.Standard.GENERAL_FREIGHT
                                else -> ""
                            }
                            val isTempRequired = EntClientDispatchManageBeV.Package.JSON.isTempRequired(pkg.json)
                            val tempInfo = if (EnumTempType.hcTempTypeList.any { it.title in EntClientDispatchManageBeV.Package.JSON.getOnlyTempInfo(pkg.json) }) EntClientDispatchManageBeV.Package.JSON.getOnlyTempInfo(pkg.json) else ""
                            val tempRequiredString = if (isTempRequired) "${EnumTempRequiredType.REQUIRED.title} - $tempInfo" else ""
                            to.remark = mutableListOf(pkg.content, pkg.memo, tempRequiredString).filter { it.isNotBlank() }.joinToString(" / ")
                        }
                    }.toMutableList()
                }
            }
            if(!target.ltGogoxResponse.priceResIsEmpty) {
                isPriceSearch = true
                priceResult = PriceRes().also { to ->
                    to.result = target.ltGogoxResponse.priceSearch.result
                    to.contents = target.ltGogoxResponse.priceSearch.contents
                    to.amount = target.ltGogoxResponse.priceSearch.amount.toString()
                }
            }
            if(!target.ltGogoxResponse.orderSubmitResIsEmpty) {
                isOrderSubmit = true
                orderSubmitResult = OrderSubmitRes().also { to ->
                    to.result = target.ltGogoxResponse.orderSubmit.result
                    to.contents = target.ltGogoxResponse.orderSubmit.contents
                    to.amount = target.ltGogoxResponse.orderSubmit.amount.toString()
                    to.orderId = target.ltGogoxResponse.orderSubmit.id.toString()
                }
            }
        })
    }
    private fun beDeliveryDeliveryDate(deliveryDate:String) = if(deliveryDate.isBlank()) "도착 예정 시간 없음" else{
        val timeCat = EnumTimeCat.time(deliveryDate.split(' ')[1])
        if(timeCat == EnumTimeCat.ETC){
            "${eDateT.part("m-d(w) H:i", deliveryDate)} 도착 예정"
        }else{
            "${eDateT.part("m-d(w)", deliveryDate)} ${timeCat.title} 도착 예정"
        }
    }
    private fun getContent(carTitle:String, deliveryDate:String, memo:String):String {
        var res = ""
        res += if(carTitle.isBlank()) "" else "$carTitle / "
        res += "$deliveryDate / "
        res += memo.ifBlank { "배송사 전달 사항 없음" }
        return res
    }
    private fun getGogoXVehicleAndRemark(carType:String):Pair<String,String> {
        return when {
            EnumDeliveryCar.QUICK.name in carType -> GogoX.Vehicle.MOTORCYCLENONE to ""
            EnumDeliveryCar.DAMAS.name in carType -> GogoX.Vehicle.DAMASNONE to ""
            EnumDeliveryCar.TRUCK.name in carType -> {
                when {
                    EnumDeliveryCarOption.LIFT.name in carType  && EnumDeliveryCarOption.ARRIVAL_FORK.name in carType -> GogoX.Vehicle.TON1NLIFT to "하차지 지게차 필요"
                    EnumDeliveryCarOption.LIFT.name in carType -> GogoX.Vehicle.TON1NLIFT to ""
                    EnumDeliveryCarOption.ARRIVAL_FORK.name in carType -> GogoX.Vehicle.TON1NONE to "하차지 지게차 필요"
                    else -> GogoX.Vehicle.TON1NONE to ""
                }
            }
            else -> "" to ""
        }
    }
    private fun getGogoXAppointAtAndRemark():Pair<String,String> {
         return when {
            EnumTimeCat.IMMEDIATELY.title in deliveryRequestDateTitle -> eDateT.part("Y-m-d H:i", eDateT.add("i", 15, regDate)) to ""
            EnumTimeCat.AM.title in deliveryRequestDateTitle -> "${eDateT.part("Y-m-d",deliveryRequestDate.split(" ")[0])} 09:00" to "${EnumTimeCat.AM.title} 픽업"
            EnumTimeCat.PM.title in deliveryRequestDateTitle -> "${eDateT.part("Y-m-d",deliveryRequestDate.split(" ")[0])} 13:00" to "${EnumTimeCat.PM.title} 픽업"
            EnumTimeCat.WORK.title in deliveryRequestDateTitle -> "${eDateT.part("Y-m-d",deliveryRequestDate.split(" ")[0])} 10:00" to "${EnumTimeCat.WORK.title} 픽업"
            EnumTimeCat.AM10.title in deliveryRequestDateTitle -> "${eDateT.part("Y-m-d",deliveryRequestDate.split(" ")[0])} 08:00" to "오전 ${EnumTimeCat.AM10.title} 픽업"
            EnumTimeCat.PM4.title in deliveryRequestDateTitle -> "${eDateT.part("Y-m-d",deliveryRequestDate.split(" ")[0])} 13:00" to "오후 ${EnumTimeCat.PM4.title} 픽업"
            else -> eDateT.part("Y-m-d H:i",deliveryRequestDate) to ""
        }
    }
    private fun getGogoXArrivalDateAndRemark():Pair<String,String> {
        return if(deliveryArrivalDate.isNotBlank()) when {
            EnumTimeCat.AM.time in deliveryArrivalDate -> "${eDateT.part("Y-m-d",deliveryArrivalDate.split(" ")[0])} 10:00" to "${EnumTimeCat.AM.title} 도착"
            EnumTimeCat.PM.time in deliveryArrivalDate -> "${eDateT.part("Y-m-d",deliveryArrivalDate.split(" ")[0])} 14:00" to "${EnumTimeCat.PM.title} 도착"
            EnumTimeCat.WORK.time in deliveryArrivalDate -> "${eDateT.part("Y-m-d",deliveryArrivalDate.split(" ")[0])} 10:00" to "${EnumTimeCat.WORK.title} 도착"
            EnumTimeCat.AM10.time in deliveryArrivalDate -> "${eDateT.part("Y-m-d",deliveryArrivalDate.split(" ")[0])} 09:00" to "오전 ${EnumTimeCat.AM10.title} 도착"
            EnumTimeCat.PM4.time in deliveryArrivalDate -> "${eDateT.part("Y-m-d",deliveryArrivalDate.split(" ")[0])} 14:00" to "오후 ${EnumTimeCat.PM4.title} 도착"
            else -> eDateT.part("Y-m-d H:i",deliveryArrivalDate) to ""
        } else "" to ""
    }
    fun init() {
        formList = mutableListOf()
        isPriceSearch = false
        isOrderSubmit = false
        isOrderSearch = false
    }
    fun addForm(){ formList.add(GogoxForm()) }}
class EntClientBeCompleteGogoX:eEntity() {
    class GogoxForm {
        var view:eView<HTMLElement>? = null
        class Address {
            var name = ""
            var tel = ""
            var addr1 = ""
            var addr2 = ""
        }
        class Goods {
            var view:eView<HTMLElement>? = null
            var type = ""
            var qty = ""
            var remark = ""
        }
        class Vehicle{
            var name1 = ""
            var name2 = ""
            var rowid = ""
        }
        var vehicleList = mutableListOf(Vehicle())
        var selectedVehicle = ""
        var apoointmentAtDate = ""
        var apoointmentAtTime = ""
        var fromAddress = Address()
        var toAddress = Address()
        var requestAtDate = ""
        var requestAtTime = ""
        var isRequestAtWrapperDisplayNone = true
        var isDescriptionWrapperDisplayNone = true
        var description = ""
        var goodsItemList = mutableListOf<String>()
        var goodsList = mutableListOf(Goods())
        fun addGoods(){ goodsList.add(Goods()) }
        fun deleteGoods(item:Goods){ goodsList.remove(item) }
        var remark = ""
    }
    class PriceRes {
        var result = ""
        var contents = ""
        var amount= ""
        val isFail get() = result != "success"
        val isSuccess get() = result == "success"
    }
    class OrderSubmitRes {
        var result = ""
        var contents = ""
        var amount= ""
        var orderId = ""
        val isFail get() = result != "success"
    }
    class OrderSearchRes {
        var result = ""
        var contents = ""
        var status= EnumOrderStatusLt.PENDING
        var request_at = ""
        var cancelled_at = ""
        var completed_at = ""
        var pickup_at = ""
        var driverName = ""
        var driverPhone = ""
        val isFail get() = result != "success"
    }
    var selectedTab: EnumBeDeliveryStateTab = EnumBeDeliveryStateTab.COMPLETED
    var deliveryRequestDateTitle = ""
    var deliveryRequestDate = "" //픽업 예정일
    var deliveryArrivalDate = "" //도착 예정일
    var beDeliveryRowid = ""
    var deliveryCarTypeJson = ""
    var deliveryCarTitle = ""
    var deliverySenderName = ""
    var deliverySenderAddr1 = ""
    var deliverySenderAddr2 = ""
    val deliverySenderAddr get() = if(deliverySenderAddr2.isNotBlank()) "$deliverySenderAddr1, $deliverySenderAddr2" else deliverySenderAddr1
    var deliverySenderTel = ""
    var deliveryReceiverName = ""
    var deliveryRecevierAddr1 = ""
    var deliveryRecevierAddr2 = ""
    val deliveryReceiverAddr get() = if(deliveryRecevierAddr2.isNotBlank()) "$deliveryRecevierAddr1, $deliveryRecevierAddr2" else deliveryRecevierAddr1
    var deliveryPackageList = mutableListOf<EntUserApiCarrierDeliveryRes.CompleteBeDelivery.DeliveryPackage>()
    var deliveryCompanyName = ""
    var deliveryRegDate = ""
    var deliveryContent = ""
    var memo = ""
    var deliveryReceiverTel = ""
    var formList = mutableListOf<GogoxForm>()
    var isPriceSearch = false //가격 조회 여부
    var isOrderSubmit = false //주문 제출 여부
    var isOrderSearch = false //주문 조회 여부
    var priceResult = PriceRes() //가격 조회 결과
    fun priceResultInit() {
        priceResult = PriceRes()
    }
    var orderSubmitResult = OrderSubmitRes() //주문 제출 결과
    var orderSearchResult = OrderSearchRes() //주문 조회 결과
    fun orderSearchResultInit() {
        orderSearchResult = OrderSearchRes()
    }
    fun setDataFrom(target: EntUserApiCarrierDeliveryRes.CompleteBeDelivery) {
        deliveryRequestDate = target.requestDate
        deliveryRequestDateTitle = """${eDateT.part("m-d", target.requestDate)} ${
            when (target.requestDateTimeCat) {
                EnumTimeCat.ETC -> eDateT.part("H:i", target.requestDate)
                EnumTimeCat.IMMEDIATELY -> "즉시 배차 - ${eDateT.part("H:i", target.regDate)}"
                else -> target.requestDateTimeCat.title
            }
        } 픽업"""
        deliveryArrivalDate = target.deliveryDate
        beDeliveryRowid = target.deliveryRowid
        deliveryCarTypeJson = target.json
        deliveryCarTitle = EntUserApiCarrierDeliveryRes.BeDelivery.getCarTitle(target.json)
        deliverySenderName = target.senderAlias.ifBlank { target.senderName }
        deliverySenderAddr1 = target.senderAddr1
        deliverySenderAddr2 = target.senderAddr2
        deliverySenderTel = target.senderPhone
        deliveryReceiverName = target.receiverAlias.ifBlank { target.receiverName }
        deliveryRecevierAddr1 = target.receiverAddr1
        deliveryRecevierAddr2 = target.receiverAddr2
        deliveryReceiverTel = target.receiverPhone
        deliveryPackageList = target.deliveryPackageList
        deliveryCompanyName = target.companyName
        deliveryRegDate = target.regDate
        memo = target.memo
        deliveryContent = getContent(deliveryCarTitle, beDeliveryDeliveryDate(target.deliveryDate), target.memo)
    }
    fun setDataFrom(target: EntUserApiGogoXRes) {
        formList = mutableListOf(GogoxForm().also{form ->
            form.vehicleList = target.vehicleCodeMap.vehicleCodeMap.map { vehicle ->
                GogoxForm.Vehicle().also { to ->
                    to.name1 = vehicle.vehicle.vehicleName1
                    to.name2 = vehicle.vehicle.vehicleName2
                }
            }.toMutableList()
            form.goodsItemList = target.vehicleCodeMap.vehicleCodeMap.flatMap { vehicle ->
                vehicle.codeList.flatMap { listOf(it.code.codeName2) }
            }.distinct().toMutableList()
            if (!target.ltGogoxRequest.isEmpty) {
                val req = target.ltGogoxRequest.orderRequest
                form.selectedVehicle = req.vehicle
                form.apoointmentAtDate = eDateT.part("Y-m-d",AppUser.toYMDHHMMSS(req.appointment_at))
                form.apoointmentAtTime = eDateT.part("H:i",AppUser.toYMDHHMMSS(req.appointment_at))
                form.requestAtDate = if(req.request_at.isNotBlank()) {
                    form.isRequestAtWrapperDisplayNone = false
                    eDateT.part("Y-m-d",AppUser.toYMDHHMMSS(req.request_at))
                } else ""
                form.requestAtTime = if(req.request_at.isNotBlank()) eDateT.part("H:i",AppUser.toYMDHHMMSS(req.request_at)) else ""
                form.description = req.description
                if(form.description.isNotBlank()) form.isDescriptionWrapperDisplayNone = false
                form.fromAddress = GogoxForm.Address().also { to ->
                    to.name = req.from.name
                    to.tel = req.from.mobile
                    to.addr1 = req.from.addr1
                    to.addr2 = req.from.addr2
                }
                form.toAddress = GogoxForm.Address().also { to ->
                    to.name = req.to.name
                    to.tel = req.to.mobile
                    to.addr1 = req.to.addr1
                    to.addr2 = req.to.addr2
                }
                form.goodsList = req.goods.map { goods ->
                    GogoxForm.Goods().also { to ->
                        to.type = goods.type
                        to.qty = goods.qty.toString()
                        to.remark = goods.remark
                    }
                }.toMutableList()
                form.remark = req.remark
                priceResult.result = "success"
            }
            if(!target.ltGogoxResponse.priceResIsEmpty) {
                isPriceSearch = true
                priceResult = PriceRes().also { to ->
                    to.result = target.ltGogoxResponse.priceSearch.result
                    to.contents = target.ltGogoxResponse.priceSearch.contents
                    to.amount = target.ltGogoxResponse.priceSearch.amount.toString()
                }
            }
            if(!target.ltGogoxResponse.orderSubmitResIsEmpty) {
                isOrderSubmit = true
                orderSubmitResult = OrderSubmitRes().also { to ->
                    to.result = target.ltGogoxResponse.orderSubmit.result
                    to.contents = target.ltGogoxResponse.orderSubmit.contents
                    to.amount = target.ltGogoxResponse.orderSubmit.amount.toString()
                    to.orderId = target.ltGogoxResponse.orderSubmit.id.toString()
                }
            }
            if(!target.ltGogoxResponse.orderSearchResIsEmpty) {
                isOrderSearch = true
                orderSearchResult = OrderSearchRes().also { to ->
                    to.result = target.ltGogoxResponse.orderSearch.result
                    to.contents = target.ltGogoxResponse.orderSearch.contents
                    to.status = EnumOrderStatusLt.toEnum(target.ltGogoxResponse.orderSearch.successData.data.status)
                    to.request_at = target.ltGogoxResponse.orderSearch.successData.data.request_at
                    to.cancelled_at =  target.ltGogoxResponse.orderSearch.successData.data.cancelled_at
                    to.completed_at =  target.ltGogoxResponse.orderSearch.successData.data.completed_at
                    to.pickup_at =  target.ltGogoxResponse.orderSearch.successData.data.pickup_at
                    to.driverName =  target.ltGogoxResponse.orderSearch.successData.data.driver.name
                    to.driverPhone = AppUser.phoneFormatter(target.ltGogoxResponse.orderSearch.successData.data.driver.phone)
                }
            }
        })
    }

    private fun beDeliveryDeliveryDate(deliveryDate:String) = if(deliveryDate.isBlank()) "도착 예정 시간 없음" else{
        val timeCat = EnumTimeCat.time(deliveryDate.split(' ')[1])
        if(timeCat == EnumTimeCat.ETC){
            "${eDateT.part("m-d(w) H:i", deliveryDate)} 도착 예정"
        }else{
            "${eDateT.part("m-d(w)", deliveryDate)} ${timeCat.title} 도착 예정"
        }
    }
    private fun getContent(carTitle:String, deliveryDate:String, memo:String):String {
        var res = ""
        res += if(carTitle.isBlank()) "" else "$carTitle / "
        res += "$deliveryDate / "
        res += memo.ifBlank { "배송사 전달 사항 없음" }
        return res
    }
    fun init() {
        formList = mutableListOf()
        isPriceSearch = true
        isOrderSubmit = true
        isOrderSearch = false
    }
    fun addForm(){ formList.add(GogoxForm()) }}
/*배송 업데이트 모달용*/
class EntClientDispatchManageBeV(target: EntUserApiCarrierDeliveryRes.CompleteBeDelivery):eEntity() {
    class Package:eEntity(true){
        var rowid:String by string(::rowid)
        var state: EnumBoundxDeliveryPackageState by enum(::state)
        var title:String by string(::title)
        var contents:String by string(::contents)
        var standard:String by string(::standard)
        var memo:String by string(::memo)
        var isReject = false
        var isComplete = false
        val isSelected get() = isReject || isComplete
        var completeDate:String by string(::completeDate) { default(eDate.part("Y-m-d", Date())) } //(배송완료/회수)일
        var completeTime:String by string(::completeTime) { default(eDate.part("H:i", Date())) } //(배송완료/회수)시간
        /** JSON으로 저장하여 Req.Package.json에 저장하는 용도의 클래스 */
        class JSON: eEntity() {
            var tempRequiredType: EnumTempRequiredType by enum(::tempRequiredType) { default(EnumTempRequiredType.REQUIRED) }
            var tempType: EnumTempType by enum(::tempType) { default(EnumTempType.REFRIGERATED) }
            companion object {
                fun getMemo(_memo:String, json:String):String = getOnlyMemo(_memo,json)
                fun getOnlyMemo(_memo:String, json:String):String {
                    var memo = _memo.ifEmpty{ "별도 지시 사항 없음" }
                    memo += " / "
                    eEntity.parse(JSON(), json)?.let { json ->
                        memo += if(json.tempRequiredType == EnumTempRequiredType.REQUIRED) json.tempType.title else json.tempRequiredType.title
                    } ?: run {
                        /*기타배송일경우에 해당*/
                        memo += EnumTempRequiredType.NOT_REQUIRED.title
                    }
                    return memo
                }
                fun getOnlyTempInfo(json:String):String {
                    eEntity.parse(JSON(), json)?.let { json ->
                        return if(json.tempRequiredType == EnumTempRequiredType.REQUIRED) json.tempType.title else json.tempRequiredType.title
                    } ?: run {
                        /*기타배송일경우에 해당*/
                        return EnumTempRequiredType.NOT_REQUIRED.title
                    }
                }
                fun isTempRequired(json:String):Boolean {
                    return eEntity.parse(JSON(), json)?.let { json -> json.tempRequiredType == EnumTempRequiredType.REQUIRED } ?: false
                }
            }
        }
    }
    class ReturnPackage:eEntity(true){
        var rowid:String by string(::rowid)
        var state: EnumBoundxReturnPackageState by enum(::state)
        var title:String by string(::title)
        var contents:String by string(::contents)
        var standard:String by string(::standard)
        var memo:String by string(::memo)
        var isFail = false
        var isComplete = false
        val isSelected get() = isFail || isComplete
        var completeDate:String by string(::completeDate) { default(eDate.part("Y-m-d", Date())) } //(배송완료/회수)일
        var completeTime:String by string(::completeTime) { default(eDate.part("H:i", Date())) } //(배송완료/회수)시간
    }
    var deliveryRowid:String by string(::deliveryRowid) { default(target.deliveryRowid) }
    var sender: EntClientDispatchDetailCompany by entity(::sender, ::EntClientDispatchDetailCompany){
        default(
            EntClientDispatchDetailCompany().also { to ->
                to.name = target.senderName
                to.addr1 = target.senderAddr1
                to.addr2 = target.senderAddr2.ifBlank { "없음" }
                to.tel = target.senderPhone
            }
        )
    }
    var receiver: EntClientDispatchDetailCompany by entity(::receiver, ::EntClientDispatchDetailCompany){
        default(
            EntClientDispatchDetailCompany().also { to ->
                to.name = target.receiverName
                to.addr1 = target.receiverAddr1
                to.addr2 = target.receiverAddr2.ifBlank { "없음" }
                to.tel = target.receiverPhone
            }
        )
    }
    var packageList: MutableList<Package> by entityList(::packageList, ::Package){
        default(
            target.deliveryPackageList.mapIndexed { idx, from ->
                Package().also { to ->
                    to.rowid = from.deliveryPackageRowid
                    to.state = from.packageState
                    to.contents = from.content
                    to.title = "패키지 ${idx+1} (송장번호: ${from.invoiceNum})"
                    to.standard = from.packageStandard.title
                    to.memo = Package.JSON.getMemo(from.memo, from.json)
                }
            }.toMutableList()
        )
    }
    var returnPackageList: MutableList<ReturnPackage> by entityList(::returnPackageList, ::ReturnPackage){
        default(
            target.returnPackageList.mapIndexed { idx, from ->
                ReturnPackage().also { to ->
                    to.rowid = from.deliveryPackageRowid
                    to.state = from.packageState
                    to.contents = from.content
                    to.title = "회수 패키지 ${idx+1}"
                    to.standard = "${from.packageStandard.title}"
                    to.memo = Package.JSON.getMemo(from.memo, from.json)
                }
            }.toMutableList()
        )
    }
    var isCancel = false
    val canCancel get() = packageList.all { !it.isSelected } && returnPackageList.all { !it.isSelected } && !isCancel
    val canSubmit get() = (packageList.all { it.isSelected } && returnPackageList.all { it.isSelected }) || isCancel
    val driverName = if(target.isExternalDriver) "외부 기사에게 위탁" else if(target.isExternalLogistics) "외부 택배사 사용" else  target.driverName
    val driverPhone = target.driverPhone
}





























