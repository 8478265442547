package medi.user

import ein2b.core.core.Now
import ein2b.core.core.err
import ein2b.core.core.jsNow
import ein2b.core.coroutine.eScheduler
import ein2b.core.view.eDomBinder
import ein2b.core.view.eView
import kotlinx.browser.document
import medi.common.app.App
import medi.user.app.ApiUser
import medi.user.app.ModulePathUser.M_HOME
import medi.user.app.RouterKeyUser
import medi.user.modalView.companyClass.companyClassModalView
import medi.user.modalView.deliveryRemove.DeliveryRemoveModalView
import medi.user.modalView.dispatchDetail.dispatchDetailView
import medi.user.modalView.dispatchFile.dispatchFileView
import medi.user.modalView.dispatchManage.dispatchManageView
import medi.user.modalView.goGoXModal.gogoxView
import medi.user.modalView.invoiceCompany.invoiceCompanyModal
import medi.user.modalView.invoiceCompanySame.invoiceCompanySameModal
import medi.user.modalView.invoiceCompanySelect.invoiceCompanySelectModal
import medi.user.modalView.invoiceExternal.externalInvoiceModal
import medi.user.modalView.itemPackage.itemPackageModal
import medi.user.modalView.packageAdd.packageAddView
import medi.user.modalView.shipperDeliveryDriverInfo.ShipperDeliveryDriverInfoModalView
import medi.user.modalView.shipperDeliveryReject.ShipperDeliveryRejectModalView
import medi.user.modalView.shipperRequestAddrChange.ShipperRequestAddrChangeModalView
import org.w3c.dom.HTMLElement
import view.*

lateinit var ani:eScheduler
lateinit var packageAddEV:eView<HTMLElement>
lateinit var dispatchDetailEV:eView<HTMLElement>
lateinit var dispatchFileEV:eView<HTMLElement>
lateinit var gogoxEV:eView<HTMLElement>
lateinit var requestAddrChangeModalEV:eView<HTMLElement>
lateinit var deliveryRejectModalEV:eView<HTMLElement>
lateinit var deliveryRemoveModalEV:eView<HTMLElement>
lateinit var deliveryDriverInfoModalEV:eView<HTMLElement>
lateinit var companyClassModalEV:eView<HTMLElement>
lateinit var itemPackageModalEV:eView<HTMLElement>
lateinit var invoiceCompanyModalEV:eView<HTMLElement>
lateinit var invoiceCompanySelectModalEV:eView<HTMLElement>
lateinit var invoiceCompanySameModalEV:eView<HTMLElement>
lateinit var externalInvoiceEV:eView<HTMLElement>
lateinit var dispatchManageEV:eView<HTMLElement>

suspend fun commonMain(){
    Now.instance = jsNow
    ani = eScheduler()
    ani.start(15)

    eView.binder = eDomBinder().apply{start()}
    ApiUser

    //View
    val viewEL by lazy{ (document.querySelector("#view") as? HTMLElement) ?: err("invalid #view") }
    packageAddEV = packageAddView()
    dispatchDetailEV = dispatchDetailView()
    dispatchFileEV = dispatchFileView()
    gogoxEV = gogoxView()
    requestAddrChangeModalEV = ShipperRequestAddrChangeModalView()
    deliveryRejectModalEV = ShipperDeliveryRejectModalView()
    deliveryRemoveModalEV = DeliveryRemoveModalView()
    deliveryDriverInfoModalEV = ShipperDeliveryDriverInfoModalView()
    companyClassModalEV = companyClassModalView()
    itemPackageModalEV = itemPackageModal()
    invoiceCompanyModalEV = invoiceCompanyModal()
    invoiceCompanySelectModalEV = invoiceCompanySelectModal()
    invoiceCompanySameModalEV = invoiceCompanySameModal()
    externalInvoiceEV = externalInvoiceModal()
    dispatchManageEV = dispatchManageView()
    listOf(
        packageAddEV, dispatchDetailEV, dispatchFileEV, gogoxEV, requestAddrChangeModalEV, deliveryRejectModalEV, deliveryRemoveModalEV,
        deliveryDriverInfoModalEV, companyClassModalEV, itemPackageModalEV, invoiceCompanyModalEV, invoiceCompanySelectModalEV,
        invoiceCompanySameModalEV, externalInvoiceEV, dispatchManageEV
    ).forEach {
        it.setTemplate()
        viewEL.appendChild(it.template!!)
    }

    CompViewLoading.init(viewEL)
    CompViewUpload.init(viewEL)
    CompViewBlocking.init(viewEL)
    CompViewAlert.init(viewEL)
    CompViewConfirm.init(viewEL)
    CompViewToast.init(viewEL)

    val baseEL by lazy{ (document.querySelector("#base") as? HTMLElement) ?: err("invalid #base") }
    CompViewLnb.init(
        baseEL,
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi2_6/2024/02/28/e2f154c1911fe3bcfc4e2a47a60006ea.svg",
        true
    ){ App.goUrl(M_HOME, RouterKeyUser.HOME) }

    /*CompViewLnb.dummyOn()
    ApiRsc.rsc("menu").also{
        CompViewLnb.dummyOff()
    }*/
}