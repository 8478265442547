package medi.user.app

import EntClientPackerDriverGroup
import EntClientPackerDriverGroupDriver
import EntClientPackerDriverGroupW
import ein2b.core.core.err
import ein2b.core.coroutine.eLaunch
import ein2b.core.entity.eEntity
import ein2b.core.log.log
import ein2b.core.net.*
import kotlinx.browser.window
import medi.common.app.App
import medi.common.enm.EnumApiErrorAction
import medi.common.enm.EnumApiErrorMethod
import medi.common.enm.db.EnumAuthorityCat
import medi.common.enm.db.EnumRoleCat
import medi.common.entity.api.user.*
import medi.common.entity.api.user.carrier.*
import medi.common.entity.api.user.common.*
import medi.common.entity.api.user.lt.*
import medi.common.entity.api.user.member.*
import medi.common.entity.api.user.packer.*
import medi.common.entity.api.user.receiver.*
import medi.common.entity.api.user.shipper.*
import medi.user.entity.account.*
import medi.user.entity.carrier.EntClientCarrierDriverGroup
import medi.user.entity.carrier.EntClientCarrierDriverGroupDriver
import medi.user.entity.carrier.EntClientCarrierDriverGroupW
import medi.user.entity.carrier.EntClientCarrierInfo
import medi.user.entity.dispatch.EntClientDispatchList
import medi.user.entity.dispatch.EntClientDispatchMap
import medi.user.entity.packer.*
import medi.user.entity.receiver.EntClientReceiverAddW
import medi.user.entity.receiver.EntClientReceiverInfo
import medi.user.entity.shipper.*
import org.w3c.files.File
import view.CompViewAlert
import view.CompViewBlocking
import view.CompViewLnb
import view.CompViewToast
import kotlin.js.Date

object ApiUser{
    init{
        eApi.sender = FetchSender()
    }
    private fun setCode(code:String) = "연결 코드: <span style='font-weight:bold;margin-left:8px'>$code</span>"
    private fun memberInit() = EntUserApiMember.Login().also{
        it.userId = "1"
        it.userName = ""
        it.roleCat = EnumRoleCat.SHIPPER
        it.profileUrl = ""
    }
    var member = memberInit()
    var isLogin = false

    val timeoffset = Date().getTimezoneOffset()
    /*val domain = if(window.location.hostname.indexOf("hc25") == -1) "http://${window.location.hostname}:${window.location.port}" else "https://hc25api.mobility42.io"*/
    val domain = when(window.location.host){
        "healthcare.boundx.net" -> "https://healthcare-api.boundx.net"
        "healthcare-dev.boundx.net" -> "https://healthcare-dev-api.boundx.net"
        else -> "http://${window.location.hostname}:${window.location.port}"
    }
    class ReqTaskItems(private vararg val items:Pair<String,Any>):eRequestTask{
        override suspend fun run(request:eRequest):Boolean{
            items.toMap().forEach{ (k,v)-> request.items[k] = v }
            return true
        }
    }
    object ReqTimeOffSet:eRequestTask{
        override suspend fun run(request:eRequest):Boolean{
            request.header("timeoffset", "$timeoffset")
            return true
        }
    }
    const val R_ENTITY = "medi/common/entity"
    private suspend fun <T:eEntity> net(reqEntity: EntUserApiReq, resEntity:T, isJson:Boolean = false, reqTask:((eApiInfo)->Unit)? = null):T? {
        CompViewBlocking.open()
        val newId = AppUser.userId
        if(newId != AppUser.sessionUserId){
            AppUser.sessionUserId = newId
            if(newId.isNotBlank() && App.loginPage) {
                App.goUrl(ModulePathUser.M_HOME, RouterKeyUser.HOME)
                return null
            }
        }
        return eApi("", eApi.DEFAULT to eApiInfo{
            url = "$domain${reqEntity._url(isJson)}"
            method = eApi.POST
            requestTask += ReqTimeOffSet

            requestTask += ReqTaskItems("mid" to AppUser.sessionUserId)
            requestTask += eRequestTask.Header("mid")

            requestTask += ReqTaskItems(R_ENTITY to {reqEntity})
            requestTask += eRequestTask.JsonFromEntity(R_ENTITY)
            reqTask?.invoke(this)
            responseTask += eResponseTask.Text
            responseTask += eResponseTask.Entity({ MainResponse{resEntity} })
        })().let{ result->
            CompViewBlocking.close()
            if(result.isOk){
                member = memberInit()
                @Suppress("UNCHECKED_CAST")
                (result.response!!.result as? MainResponse<T>)?.let{
                    isLogin = it.isLogin
                    if(isLogin) {
                        (it.member as? EntUserApiMember.Login)?.also { mem ->
                            member = mem
                            AppUser.sessionUserId = mem.userId
                            AppUser.userId = mem.userId
                            val userMenu = mutableListOf<CompViewLnb.MenuUser.UserMenu>()
                            userMenu += CompViewLnb.MenuUser.UserMenu("key1", "내 정보"){
                                App.goUrl(
                                    ModulePathUser.M_MEMBER,
                                    RouterKeyUser.MY_INFO
                                )
                            }
                            userMenu += CompViewLnb.MenuUser.UserMenu("key2", "비밀번호 변경"){
                                App.goUrl(
                                    ModulePathUser.M_MEMBER,
                                    RouterKeyUser.CHANGE_PASSWORD
                                )
                            }
                            if(mem.roleCat == EnumRoleCat.MASTER) userMenu += CompViewLnb.MenuUser.UserMenu("key3", "서브 계정 관리"){
                                App.goUrl(
                                    ModulePathUser.M_MEMBER,
                                    RouterKeyUser.SUB_ACCOUNT
                                )
                            }
                            userMenu += CompViewLnb.MenuUser.UserMenu("key4", "로그아웃", "logout"){
                                eLaunch {
                                    logout(EntUserApiMemberLogoutReq().also { req ->
                                        req.userId = mem.userId
                                    })?.also { App.goUrl(ModulePathUser.M_HOME, RouterKeyUser.LOGIN) }
                                }
                            }
                            CompViewLnb.menuUser = CompViewLnb.MenuUser(
                                mem.profileUrl, mem.roleCat.imageUrlWhite, mem.userName, "@${mem.userId}"
                            ).apply {
                                menuList = userMenu
                            }
                            CompViewLnb.setUser()
                            val shipperList = mutableListOf<EnumAuthorityCat>()
                            val packerList = mutableListOf<EnumAuthorityCat>()
                            val carrierList = mutableListOf<EnumAuthorityCat>()
                            val receiverList = mutableListOf<EnumAuthorityCat>()
                            val urlIcon = "//s3.ap-northeast-2.amazonaws.com/m42admin-v1/2021/10/08/64ac7d621e7df9cef186ea8ff012b09a.svg"
                            mem.authorityCatList.forEach { authorityCat ->
                                when(authorityCat.roleCat) {
                                    EnumRoleCat.SHIPPER -> shipperList += authorityCat
                                    EnumRoleCat.PACKER -> packerList += authorityCat
                                    EnumRoleCat.CARRIER -> carrierList += authorityCat
                                    EnumRoleCat.RECEIVER -> receiverList += authorityCat
                                    else -> {}
                                }
                            }
                            val menuList = mutableListOf<CompViewLnb.MenuItem>()
                            if(shipperList.isNotEmpty()) menuList += CompViewLnb.MenuItem(
                                EnumRoleCat.SHIPPER.imageUrlLnb,
                                "배송의뢰사",
                                urlIcon = if(mem.roleCat == EnumRoleCat.MASTER) urlIcon else "",
                                clickBlock = {
                                    if(mem.roleCat == EnumRoleCat.MASTER) App.goUrl(
                                        ModulePathUser.M_SHIPPER,
                                        RouterKeyUser.SHIPPER_INFO
                                    )
                                }
                            ).apply{
                                subList = shipperList.map{ cat ->
                                    when(cat){
                                        EnumAuthorityCat.SHIPPER_DELIVERY_REQUEST -> CompViewLnb.MenuItem.SubItem(
                                            RouterKeyUser.SHIPPER_DELIVERY_REQUEST_ADD, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_SHIPPER,
                                                RouterKeyUser.SHIPPER_DELIVERY_REQUEST_ADD
                                            )
                                        } }
                                        EnumAuthorityCat.SHIPPER_DELIVERY_LIST -> CompViewLnb.MenuItem.SubItem(RouterKeyUser.SHIPPER_DELIVERY_LIST, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_SHIPPER,
                                                RouterKeyUser.SHIPPER_DELIVERY_LIST
                                            )
                                        } }
                                        EnumAuthorityCat.SHIPPER_ITEM_MANAGEMENT -> CompViewLnb.MenuItem.SubItem(RouterKeyUser.SHIPPER_ITEM_LIST, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_SHIPPER,
                                                RouterKeyUser.SHIPPER_ITEM_LIST
                                            )
                                        } }
                                        EnumAuthorityCat.SHIPPER_PACKER_MANAGEMENT -> CompViewLnb.MenuItem.SubItem(RouterKeyUser.SHIPPER_PACKER_LIST, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_SHIPPER,
                                                RouterKeyUser.SHIPPER_PACKER_LIST
                                            )
                                        } }
                                        EnumAuthorityCat.SHIPPER_DRIVER_MANAGEMENT -> CompViewLnb.MenuItem.SubItem(RouterKeyUser.SHIPPER_DRIVER_GROUP_LIST, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_SHIPPER,
                                                RouterKeyUser.SHIPPER_DRIVER_GROUP_LIST
                                            )
                                        } }
                                        else -> err("잘못된 shipper lnb 리스트 입니다")
                                    }
                                }.toMutableList()
                            }
                            if(packerList.isNotEmpty()) menuList += CompViewLnb.MenuItem(
                                EnumRoleCat.PACKER.imageUrlLnb,
                                "포장사",
                                urlIcon = if(mem.roleCat == EnumRoleCat.MASTER) urlIcon else "",
                                info = mem.companyCodes[EnumRoleCat.PACKER]?.let { setCode(it) } ?: "",
                                clickBlock = { if(mem.roleCat == EnumRoleCat.MASTER) App.goUrl(
                                    ModulePathUser.M_PACKER,
                                    RouterKeyUser.PACKER_INFO
                                )
                                }
                            ).apply{
                                subList = packerList.filter { (mem.roleCat == EnumRoleCat.MASTER && !it.isPrivate) || mem.roleCat != EnumRoleCat.MASTER }.map{ cat ->
                                    when(cat){
                                        EnumAuthorityCat.PACKER_PACKAGE_LIST ->  CompViewLnb.MenuItem.SubItem(RouterKeyUser.PACKAGE_LIST, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_PACKER,
                                                RouterKeyUser.PACKAGE_LIST
                                            )
                                        } }
                                        EnumAuthorityCat.PACKER_DELIVERY_LIST -> CompViewLnb.MenuItem.SubItem(RouterKeyUser.PACKER_DELIVERY_SHIPPING, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_PACKER,
                                                RouterKeyUser.PACKER_DELIVERY_SHIPPING
                                            )
                                        } }
                                        EnumAuthorityCat.PACKER_SENSOR_SESSION -> CompViewLnb.MenuItem.SubItem(RouterKeyUser.PACKER_SENSOR_SESSION_LIST, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_PACKER,
                                                RouterKeyUser.PACKER_SENSOR_SESSION_LIST
                                            )
                                        } }
                                        EnumAuthorityCat.PACKER_SENSOR_MAPPING -> CompViewLnb.MenuItem.SubItem(RouterKeyUser.PACKER_MAPPING, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_PACKER,
                                                RouterKeyUser.PACKER_MAPPING
                                            )
                                        } }
                                        EnumAuthorityCat.PACKER_DELIVERY_INFO -> CompViewLnb.MenuItem.SubItem(RouterKeyUser.PACKER_INVOICE_ADD, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_PACKER,
                                                RouterKeyUser.PACKER_INVOICE_ADD
                                            )
                                        } }
                                        EnumAuthorityCat.PACKER_DECLINE_LIST ->  CompViewLnb.MenuItem.SubItem(RouterKeyUser.PACKAGE_DECLINE_LIST, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_PACKER,
                                                RouterKeyUser.PACKAGE_DECLINE_LIST
                                            )
                                        } }
                                        EnumAuthorityCat.PACKER_CARRIER_MANAGEMENT -> CompViewLnb.MenuItem.SubItem(
                                            RouterKeyUser.PACKER_CARRIER_LIST, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_PACKER,
                                                RouterKeyUser.PACKER_CARRIER_LIST
                                            )
                                        } }
                                        EnumAuthorityCat.PACKER_DRIVER_MANAGEMENT ->  CompViewLnb.MenuItem.SubItem(
                                            RouterKeyUser.PACKER_DRIVER_GROUP_LIST, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_PACKER,
                                                RouterKeyUser.PACKER_DRIVER_GROUP_LIST
                                            )
                                        } }
                                        EnumAuthorityCat.PHARMACISTS ->  CompViewLnb.MenuItem.SubItem(RouterKeyUser.PACKER_MEMBER_DELIVERY, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_PACKER,
                                                RouterKeyUser.PACKER_MEMBER_DELIVERY
                                            )
                                        } }
                                        else -> err("잘못된 packer lnb 리스트 입니다")
                                    }
                                }.toMutableList()
                            }
                            if(carrierList.isNotEmpty()) menuList += CompViewLnb.MenuItem(
                                EnumRoleCat.CARRIER.imageUrlLnb,
                                "배송사",
                                urlIcon = if(mem.roleCat == EnumRoleCat.MASTER) urlIcon else "",
                                info = mem.companyCodes[EnumRoleCat.CARRIER]?.let { setCode(it) } ?: "",
                                clickBlock = { if(mem.roleCat == EnumRoleCat.MASTER) App.goUrl(
                                    ModulePathUser.M_CARRIER,
                                    RouterKeyUser.CARRIER_INFO
                                )
                                }
                            ).apply{
                                subList = carrierList.map{ cat ->
                                    when(cat){
                                        EnumAuthorityCat.CARRIER_DELIVERY_LIST -> CompViewLnb.MenuItem.SubItem(RouterKeyUser.DISPATCH_LIST, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_CARRIER,
                                                RouterKeyUser.DISPATCH_LIST
                                            )
                                        } }
                                        EnumAuthorityCat.CARRIER_DRIVER_MANAGEMENT -> CompViewLnb.MenuItem.SubItem(
                                            RouterKeyUser.CARRIER_DRIVER_GROUP_LIST, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_CARRIER,
                                                RouterKeyUser.CARRIER_DRIVER_GROUP_LIST
                                            )
                                        } }
                                        else -> err("잘못된 carrier lnb 리스트 입니다")
                                    }
                                }.toMutableList()
                            }
                            if(receiverList.isNotEmpty()) menuList += CompViewLnb.MenuItem(
                                EnumRoleCat.RECEIVER.imageUrlLnb,
                                "수령사",
                                urlIcon = if(mem.roleCat == EnumRoleCat.MASTER) urlIcon else "",
                                clickBlock = { if(mem.roleCat == EnumRoleCat.MASTER) App.goUrl(
                                    ModulePathUser.M_RECEIVER,
                                    RouterKeyUser.RECEIVER_INFO
                                )
                                }
                            ).apply{
                                subList = receiverList.map{ cat ->
                                    when(cat){
                                        EnumAuthorityCat.RECEIVER_DELIVER_LIST -> CompViewLnb.MenuItem.SubItem(RouterKeyUser.RECEIVER_LIST, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_RECEIVER,
                                                RouterKeyUser.RECEIVER_LIST
                                            )
                                        } }
                                        EnumAuthorityCat.RECEIVER_ADDR_MANAGEMENT -> CompViewLnb.MenuItem.SubItem(
                                            RouterKeyUser.RECEIVER_ADDR, cat.title).also{ it.clickBlock = {
                                            App.goUrl(
                                                ModulePathUser.M_RECEIVER,
                                                RouterKeyUser.RECEIVER_ADDR
                                            )
                                        } }
                                        else -> err("잘못된 receiver lnb 리스트 입니다")
                                    }
                                }.toMutableList()
                            }
                            CompViewLnb.menuList = menuList
                            CompViewLnb.setList()
                        }
                    }else (it.member as? EntUserApiMember.Logout)?.also{
                        AppUser.sessionUserId = ""
                        AppUser.userId = ""
                    }
                    if(it.isError){
                        error(it.error)
                        null
                    }else it.data
                }
            }else{
                log(result.err)
                null
            }
        }
    }
    suspend fun error(e: EntUserApiResponse.Error, block:(()->Unit)? = null) {
        when(e.method){
            EnumApiErrorMethod.ALERT -> {
                when(e.action){
                    EnumApiErrorAction.MOVE_TO_LOGIN -> App.goUrl(ModulePathUser.M_HOME, RouterKeyUser.LOGIN)
                    EnumApiErrorAction.NONE -> CompViewAlert.open(e.message, block = block)
                    EnumApiErrorAction.RELOAD -> CompViewAlert.open(e.message){ App.reload() }
                    EnumApiErrorAction.MOVE_TO_PACKAGE_DETAIL -> CompViewAlert.open(e.message) { App.goUrl(ModulePathUser.M_PACKER, RouterKeyUser.PACKAGE_DETAIL, "r" to e.data["r"]!!) }
                    else -> {}
                }
            }
            EnumApiErrorMethod.TOAST ->{
                if(block != null) block()
                CompViewToast.open(e.message)
            }
            EnumApiErrorMethod.TOAST_MOVE -> {
                if(e.message.isNotEmpty()) AppUser.setFrontMsg(e.message)
                when(e.action){
                    EnumApiErrorAction.MOVE_TO_MAIN -> App.goUrl(ModulePathUser.M_HOME, RouterKeyUser.HOME)
                    EnumApiErrorAction.BACK -> window.history.back()
                    else -> {}
                }
            }
            else -> console.log(e.message)
        }
    }

    suspend fun home(req:EntUserApiHomeReq = EntUserApiHomeReq()):EntUserApiHomeRes?{
        return net(req, EntUserApiHomeRes())
    }
    suspend fun rsa(): EntUserApiRsaRes? = net(EntUserApiRsaReq(), EntUserApiRsaRes())
    suspend fun dispatchList(req: EntUserApiCarrierDeliveryReq): EntClientDispatchList? = net(req, EntUserApiCarrierDeliveryRes())?.let{
        EntClientDispatchList(it)
    }
    suspend fun deliveryDownload(req: EntUserApiCarrierDeliveryDownloadReq): EntUserApiCarrierDeliveryDownloadRes? = net(req, EntUserApiCarrierDeliveryDownloadRes())
    suspend fun login(req:EntUserApiMemberLoginReq):EntUserApiMemberLoginRes? = net(req, EntUserApiMemberLoginRes())
    suspend fun loginCheck():EntUserApiMemberLoginCheckRes?{
//        ApiRsc.rsc("user_login")
        return net(EntUserApiMemberLoginCheckReq(), EntUserApiMemberLoginCheckRes())
    }
    suspend fun accessKeyV(req:EntUserApiMemberAccessKeyVReq):EntUserApiMemberAccessKeyVRes? = net(req, EntUserApiMemberAccessKeyVRes()) //엑세스키 조회
    suspend fun logout(req:EntUserApiMemberLogoutReq):EntUserApiMemberLogoutRes? = net(req, EntUserApiMemberLogoutRes())
    suspend fun join(req:EntUserApiMemberMasterJoinWpReq):EntUserApiMemberMasterJoinWpRes? = net(req, EntUserApiMemberMasterJoinWpRes())
    //suspend fun carrierDriverGroupList(req:EntApiCarrierDriverGroupReq=EntApiCarrierDriverGroupReq()):EntClientCarrierDriverGroupList? = net(req, EntApiCarrierDriverGroupRes(), true)?.let { EntClientCarrierDriverGroupList(it) }
    suspend fun dispatchWp(req:EntUserApiCarrierDeliveryWpReq):EntUserApiCarrierDeliveryWpRes? = net(req, EntUserApiCarrierDeliveryWpRes())
    suspend fun invoicePrint(req:EntUserApiCarrierInvoicePrintReq):EntUserApiCarrierInvoicePrintRes? = net(req, EntUserApiCarrierInvoicePrintRes())
    suspend fun memberE(req:EntUserApiMemberEReq): EntClientMemberE? = net(req, EntUserApiMemberERes())?.let { EntClientMemberE(it) }
    //배송의뢰사 - 주소 관리
    suspend fun shipperInfoE(req:EntUserApiShipperEReq): EntUserApiShipperERes? = net(req, EntUserApiShipperERes())
    suspend fun shipperInfoEp(req:EntUserApiShipperEpReq): EntUserApiShipperEpRes? = net(req, EntUserApiShipperEpRes())
    //배송의뢰사 - 기사 그룹 관리
    suspend fun shipperDriverGroupList(req:EntUserApiShipperDriverGroupReq): EntClientShipperDriverGroup? = net(req, EntUserApiShipperDriverGroupRes())?.let { EntClientShipperDriverGroup(it) }
    suspend fun shipperDriverGroupHideEp(req:EntUserApiShipperDriverGroupEpReq):EntUserApiShipperDriverGroupEpRes? = net(req, EntUserApiShipperDriverGroupEpRes())
    suspend fun shipperDriverGroupW(req:EntUserApiShipperDriverGroupWReq): EntClientShipperDriverGroupW? = net(req, EntUserApiShipperDriverGroupWRes())?.let{ EntClientShipperDriverGroupW(it) }
    suspend fun shipperDriverGroupWp(req:EntUserApiShipperDriverGroupWpReq ): EntUserApiShipperDriverGroupWpRes? = net(req, EntUserApiShipperDriverGroupWpRes())
    suspend fun shipperDriverManagement(req: EntUserApiShipperDriverGroupDriverWReq): EntClientShipperDriverGroupDriver? = net(req, EntUserApiShipperDriverGroupDriverWRes())?.let{ EntClientShipperDriverGroupDriver(it) }
    suspend fun shipperDriverGroupDriverWp(req: EntUserApiShipperDriverGroupDriverWpReq): EntUserApiShipperDriverGroupDriverWpRes? = net(req, EntUserApiShipperDriverGroupDriverWpRes())
    suspend fun shipperDriverGroupDriverHideEp(req: EntUserApiShipperDriverGroupDriverHideEpReq): EntUserApiShipperDriverGroupDriverHideEpRes? = net(req, EntUserApiShipperDriverGroupDriverHideEpRes())
    //배송의뢰사 - 배송 리스트
    suspend fun shipperDeliveryList(req: EntUserApiShipperDeliveryReq): EntClientShipperDelivery? = net(req, EntUserApiShipperDeliveryRes())?.let { EntClientShipperDelivery(it) }
    suspend fun shipperDeliveryV(req: EntUserApiShipperDeliveryVReq): EntClientShipperDeliveryV? = net(req, EntUserApiShipperDeliveryVRes())?.let { EntClientShipperDeliveryV(it) }
    suspend fun shipperDeliveryRejectWp(req: EntUserApiShipperDeliveryRejectWpReq): EntUserApiShipperDeliveryRejectWpRes? = net(req, EntUserApiShipperDeliveryRejectWpRes())
    suspend fun shipperDeliveryDp(req: EntUserApiShipperDeliveryDpReq): EntUserApiShipperDeliveryDpRes? = net(req, EntUserApiShipperDeliveryDpRes())
    suspend fun changePwE(req:EntUserApiMemberPwEReq):EntUserApiMemberPwERes? = net(req, EntUserApiMemberPwERes())
    suspend fun changePwEp(req:EntUserApiMemberPwEpReq):EntUserApiMemberPwEpRes? = net(req, EntUserApiMemberPwEpRes())
    suspend fun memberEp(req:EntUserApiMemberEpReq):EntUserApiMemberEpRes? = net(req, EntUserApiMemberEpRes())
    suspend fun imageWp(upfile: File, req: EntUserApiImageWpReq): EntUserApiImageWpRes? = net(req, EntUserApiImageWpRes()){
        it.requestTask += ReqTaskItems("upfile" to upfile)
        it.requestTask += eRequestTask.BlobFile("upfile")
    }
    //포장사 - 주소 관리
    suspend fun packerInfoE(req:EntUserApiPackerEReq): EntUserApiPackerERes? = net(req, EntUserApiPackerERes())
    suspend fun packerInfoEp(req:EntUserApiPackerEpReq): EntUserApiPackerEpRes? = net(req, EntUserApiPackerEpRes())
    //포장 리스트
    suspend fun packageList(req:EntUserApiPackerDeliveryReq): EntClientPackageList? = net(req, EntUserApiPackerDeliveryRes())?.let{
//        console.log(it.stringify())
        EntClientPackageList(it)
    }
    suspend fun packageV(req:EntUserApiPackerDeliveryVReq): EntClientPackerPackageV? = net(req, EntUserApiPackerDeliveryVRes())?.let{ EntClientPackerPackageV(it) }
    suspend fun packageW(req:EntUserApiPackerDeliveryWReq): EntClientPackageW? = net(req, EntUserApiPackerDeliveryWRes())?.let{ EntClientPackageW(it) }
    //포장상세 - 포장형태 리스트(모달)
    suspend fun packerItemPackage(req:EntUserApiPackerItemPackageReq):EntUserApiPackerItemPackageRes? = net(req, EntUserApiPackerItemPackageRes())
    suspend fun packerItemPackageWp(req:EntUserApiPackerItemPackageWpReq):EntUserApiPackerItemPackageWpRes? = net(req, EntUserApiPackerItemPackageWpRes())
    suspend fun packerItemPackageHideEp(req:EntUserApiPackerItemPackageHideEpReq):EntUserApiPackerItemPackageHideEpRes? = net(req, EntUserApiPackerItemPackageHideEpRes())
    suspend fun packerItemPackageDefaultEp(req:EntUserApiPackerItemPackageDefaultEpReq):EntUserApiPackerItemPackageDefaultEpRes? = net(req, EntUserApiPackerItemPackageDefaultEpRes())
    //포장상세 - 외부 송장 리스트 리스트(모달)
    suspend fun packerExternalInvoice(req:EntUserApiPackerDeliveryPackageExternalInvoiceReq):EntUserApiPackerDeliveryPackageExternalInvoiceRes? = net(req, EntUserApiPackerDeliveryPackageExternalInvoiceRes())
    suspend fun packerExternalInvoiceWp(req:EntUserApiPackerDeliveryPackageExternalInvoiceWpReq):EntUserApiPackerDeliveryPackageExternalInvoiceWpRes? = net(req, EntUserApiPackerDeliveryPackageExternalInvoiceWpRes())
    suspend fun packerExternalInvoiceDp(req:EntUserApiPackerDeliveryPackageExternalInvoiceDpReq):EntUserApiPackerDeliveryPackageExternalInvoiceDpRes? = net(req, EntUserApiPackerDeliveryPackageExternalInvoiceDpRes())

    suspend fun packageWp(req:EntUserApiPackerDeliveryWpReq):EntUserApiPackerDeliveryWpRes? = net(req, EntUserApiPackerDeliveryWpRes())
    suspend fun packerDeliveryPackageWp(req:EntUserApiPackerDeliveryPackageWpReq):EntClientPackageW? = net(req, EntUserApiPackerDeliveryPackageWpRes())?.let{ EntClientPackageW(it) }
    suspend fun packerDeliveryPackageDp(req:EntUserApiPackerDeliveryPackageDpReq):EntUserApiPackerDeliveryPackageDpRes? = net(req, EntUserApiPackerDeliveryPackageDpRes())

    suspend fun codeMappingW(req:EntUserApiPackerCodeMappingWReq): EntClientPackerCodeMappingW? = net(req, EntUserApiPackerCodeMappingWRes())?.let{ EntClientPackerCodeMappingW(it) }
    suspend fun codeMappingWp(req:EntUserApiPackerCodeMappingWpReq): EntUserApiPackerCodeMappingWpRes? = net(req, EntUserApiPackerCodeMappingWpRes())
    suspend fun memberSub(req:EntUserApiMemberSubReq): EntClientMemberSub? = net(req, EntUserApiMemberSubRes())?.let { EntClientMemberSub(it) }
    suspend fun memberSubW(req:EntUserApiMemberSubWReq): EntClientMemberSubW? = net(req, EntUserApiMemberSubWRes())?.let{ EntClientMemberSubW(it) }
    suspend fun memberSubWp(req:EntUserApiMemberSubWpReq): EntUserApiMemberSubWpRes? = net(req, EntUserApiMemberSubWpRes())

    //배송 의뢰(등록)
    suspend fun shipperDeliveryRequestW(req:EntUserApiShipperDeliveryRequestWReq):EntUserApiShipperDeliveryRequestWRes? = net(req, EntUserApiShipperDeliveryRequestWRes())
    suspend fun shipperDeliveryRequestWp(req:EntUserApiShipperDeliveryRequestWpReq):EntUserApiShipperDeliveryRequestWpRes? = net(req, EntUserApiShipperDeliveryRequestWpRes())
    //의약품 관리(리스트)
    suspend fun shipperItem(req:EntUserApiShipperItemReq):EntUserApiShipperItemRes? = net(req, EntUserApiShipperItemRes())
    suspend fun shipperItemNickWp(req:EntUserApiShipperItemNickWpReq):EntUserApiShipperItemNickWpRes? = net(req, EntUserApiShipperItemNickWpRes())
    //의약품 등록 요청
    suspend fun shipperItemW(req:EntUserApiShipperItemRequestWReq):EntUserApiShipperItemRequestWRes? = net(req, EntUserApiShipperItemRequestWRes())
    suspend fun shipperItemWp(req:EntUserApiShipperItemRequestWpReq):EntUserApiShipperItemRequestWpRes? = net(req, EntUserApiShipperItemRequestWpRes())
    //포장사 관리(리스트)
    suspend fun shipperPacker(req:EntUserApiShipperPackerReq):EntUserApiShipperPackerRes? = net(req, EntUserApiShipperPackerRes())
    suspend fun shipperPackerSearch(req:EntUserApiShipperPackerSearchReq):EntUserApiShipperPackerSearchRes? = net(req, EntUserApiShipperPackerSearchRes())
    //포장사 연결
    suspend fun shipperPackerW(req:EntUserApiShipperPackerWReq): EntClientShipperPackerW? = net(req, EntUserApiShipperPackerWRes())?.let{ EntClientShipperPackerW(it) }
    suspend fun shipperPackerWp(req:EntUserApiShipperPackerWpReq):EntUserApiShipperPackerWpRes? = net(req, EntUserApiShipperPackerWpRes())

    //수령사 - 주소 관리
    suspend fun receiverAddrW(req: EntUserApiReceiverAddrReq): EntClientReceiverAddW? = net(req, EntUserApiReceiverAddrRes())?.let { EntClientReceiverAddW(it) }
    suspend fun receiverAddrWp(req:EntUserApiReceiverAddrWpReq):EntUserApiReceiverAddrWpRes? = net(req, EntUserApiReceiverAddrWpRes())
    suspend fun receiverAddrEp(req:EntUserApiReceiverAddrEpReq):EntUserApiReceiverAddrEpRes? = net(req, EntUserApiReceiverAddrEpRes())
    suspend fun receiverE(req:EntUserApiReceiverEReq): EntClientReceiverInfo? = net(req, EntUserApiReceiverERes())?.let { EntClientReceiverInfo(it) }
    suspend fun receiverEp(req:EntUserApiReceiverEpReq):EntUserApiReceiverAddrEpRes? = net(req, EntUserApiReceiverAddrEpRes())

    //수령사 - 수령 리스트
    suspend fun receiverDelivery(req:EntUserApiReceiverDeliveryReq): EntClientShipperDelivery? = net(req, EntUserApiReceiverDeliveryRes())?.let { EntClientShipperDelivery(it) }
    suspend fun receiverDeliveryV(req: EntUserApiReceiverDeliveryVReq): EntClientShipperDeliveryV? = net(req, EntUserApiReceiverDeliveryVRes())?.let { EntClientShipperDeliveryV(it) }

    //포장 완료- 패키지 등록-박스센서 매핑(모달)
    //송장 출력(프린트)
    //출하 확인
    suspend fun packerPharmacistDeliveryEp(req:EntUserApiPackerPharmacistEpReq):EntUserApiPackerPharmacistEpRes? = net(req, EntUserApiPackerPharmacistEpRes())
    //출하증명서 조회
    suspend fun packerPharmacistDeliveryV(req:EntUserApiPackerPharmacistDeliveryVReq):EntUserApiPackerPharmacistDeliveryVRes? = net(req, EntUserApiPackerPharmacistDeliveryVRes())
    //출하 리스트
    suspend fun packerPharmacistDelivery(req:EntUserApiPackerPharmacistDeliveryReq):EntUserApiPackerPharmacistDeliveryRes? = net(req, EntUserApiPackerPharmacistDeliveryRes())
    //연결 회사 구분 수정
    suspend fun masterCompanyClassEp(req: EntUserApiUtilMasterCompanyClassEpReq): EntUserApiUtilMasterCompanyClassEpRes? = net(req, EntUserApiUtilMasterCompanyClassEpRes())
    //연결 회사 구분(모달)
    suspend fun companyClass(req: EntUserApiUtilCompanyClassReq): EntUserApiUtilCompanyClassRes? = net(req, EntUserApiUtilCompanyClassRes())
    suspend fun companyClassWp(req: EntUserApiUtilCompanyClassWpReq): EntUserApiUtilCompanyClassWpRes? = net(req, EntUserApiUtilCompanyClassWpRes())
    suspend fun companyClassHideEp(req: EntUserApiUtilCompanyClassHReq): EntUserApiUtilCompanyClassHRes? = net(req, EntUserApiUtilCompanyClassHRes())
    //운송사(모달)
    suspend fun invoiceCompany(req:EntUserApiPackerInvoiceCompanyReq):EntUserApiPackerInvoiceCompanyRes? = net(req, EntUserApiPackerInvoiceCompanyRes())
    suspend fun invoiceCompanyWp(req:EntUserApiPackerInvoiceCompanyWpReq):EntUserApiPackerInvoiceCompanyWpRes? = net(req, EntUserApiPackerInvoiceCompanyWpRes())
    suspend fun invoiceCompanyHideEp(req:EntUserApiPackerInvoiceCompanyHideEpReq):EntUserApiPackerInvoiceCompanyHideEpRes? = net(req, EntUserApiPackerInvoiceCompanyHideEpRes())
    suspend fun invoiceCompanyDefaultEp(req:EntUserApiPackerInvoiceCompanyDefaultEpReq):EntUserApiPackerInvoiceCompanyDefaultEpRes? = net(req, EntUserApiPackerInvoiceCompanyDefaultEpRes())

    //송장 정보 리스트
    suspend fun packerInvoice(req:EntUserApiPackerInvoiceReq):EntUserApiPackerInvoiceRes? = net(req, EntUserApiPackerInvoiceRes())
    //송장 정보 입력(수정)
    suspend fun packerInvoiceEp(req:EntUserApiPackerInvoiceEpReq):EntUserApiPackerInvoiceEpRes? = net(req, EntUserApiPackerInvoiceEpRes())
    //송장-센서 매핑 준비
    suspend fun packerMappingW(req:EntUserApiPackerMappingWReq):EntUserApiPackerMappingWRes? = net(req, EntUserApiPackerMappingWRes())
    //센서 중복 확인
    suspend fun packerMappingSensorOverlap(req:EntUserApiPackerMappingOverlapReq):EntUserApiPackerMappingOverlapRes? = net(req, EntUserApiPackerMappingOverlapRes())
    //송장-센서 매핑
    suspend fun packerMappingWp(req:EntUserApiPackerMappingWpReq):EntUserApiPackerInvoiceCompanyWpRes? = net(req, EntUserApiPackerInvoiceCompanyWpRes())
    //송장-센서 매핑 (매핑 내역 리스트)
    suspend fun packerMapping(req:EntUserApiPackerMappingReq):EntUserApiPackerMappingRes? = net(req, EntUserApiPackerMappingRes())
    //매핑 취소
    suspend fun packerMappingDp(req:EntUserApiPackerMappingDpReq):EntUserApiPackerMappingDpRes? = net(req, EntUserApiPackerMappingDpRes())

    // 포장사 - 배송리스트
    suspend fun packerDeliveryShippingList(req:EntUserApiPackerDeliveryShippingReq): EntClientShipperDelivery? = net(req, EntUserApiPackerDeliveryShippingRes())?.let { EntClientShipperDelivery(it) }
    suspend fun packerDeliveryShippingV(req:EntUserApiPackerDeliveryShippingVReq): EntClientShipperDeliveryV? = net(req, EntUserApiPackerDeliveryShippingVRes())?.let { EntClientShipperDeliveryV(it) }
    suspend fun codeMappingD(req:EntUserApiPackerCodeMappingDReq): EntUserApiPackerCodeMappingDRes? = net(req, EntUserApiPackerCodeMappingDRes())
    suspend fun codeMappingDp(req:EntUserApiPackerCodeMappingDpReq): EntUserApiPackerCodeMappingDpRes? = net(req, EntUserApiPackerCodeMappingDpRes())

    suspend fun memberSubE(req:EntUserApiMemberSubEReq): EntClientMemberSubE? = net(req, EntUserApiMemberSubERes())?.let{ EntClientMemberSubE(it) }
    suspend fun memberSubEp(req:EntUserApiMemberSubEpReq): EntUserApiMemberSubEpRes? = net(req, EntUserApiMemberSubEpRes())
    suspend fun memberSub2W(req:EntUserApiMemberSubPharmacistWReq): EntClientMemberSubPharmacistW? = net(req, EntUserApiMemberSubPharmacistWRes())?.let{ EntClientMemberSubPharmacistW(it) }
    suspend fun memberSub2Wp(req:EntUserApiMemberSubPharmacistWpReq): EntUserApiMemberSubPharmacistWpRes? = net(req, EntUserApiMemberSubPharmacistWpRes())
    suspend fun memberSub2E(req:EntUserApiMemberSubPharmacistEReq): EntClientMemberSubPharmacistE? = net(req, EntUserApiMemberSubPharmacistERes())?.let{ EntClientMemberSubPharmacistE(it) }
    suspend fun memberSub2Ep(req:EntUserApiMemberSubPharmacistEpReq): EntUserApiMemberSubPharmacistEpRes? = net(req, EntUserApiMemberSubPharmacistEpRes())

    suspend fun declineReport(req:EntUserApiPackerDeliveryPackageRejectReportReq): EntClientPackerDeclineReport? = net(req, EntUserApiPackerDeliveryPackageRejectReportRes())?.let{ EntClientPackerDeclineReport(it) }
    suspend fun declineReportHide(req:EntUserApiPackerDeliveryPackageRejectReportHideEpReq): EntUserApiPackerDeliveryPackageRejectReportHideEpRes? = net(req, EntUserApiPackerDeliveryPackageRejectReportHideEpRes())

    suspend fun packerCarrier(req:EntUserApiPackerCarrierReq): EntClientPackerCarrier? = net(req, EntUserApiPackerCarrierRes())?.let{ EntClientPackerCarrier(it) }
    suspend fun packerCarrierW(req:EntUserApiPackerCarrierWReq): EntClientPackerCarrierW? = net(req, EntUserApiPackerCarrierWRes())?.let{ EntClientPackerCarrierW(it) }
    suspend fun packerCarrierSearch(req:EntUserApiPackerCarrierSearchReq):EntUserApiPackerCarrierSearchRes? = net(req, EntUserApiPackerCarrierSearchRes())
    suspend fun packerCarrierWp(req:EntUserApiPackerCarrierWpReq):EntUserApiPackerCarrierWpRes? = net(req, EntUserApiPackerCarrierWpRes())
    //포장사에 생긴 기사그룹관리
    suspend fun packerDriverGroupList(req: EntUserApiPackerDriverGroupReq):EntClientPackerDriverGroup? = net(req, EntUserApiPackerDriverGroupRes())?.let { EntClientPackerDriverGroup(it) }
    suspend fun packerDriverGroupHideEp(req: EntUserApiPackerDriverGroupEpReq):EntUserApiPackerDriverGroupEpRes? = net(req, EntUserApiPackerDriverGroupEpRes())
    suspend fun packerDriverGroupW(req: EntUserApiPackerDriverGroupWReq): EntClientPackerDriverGroupW? = net(req, EntUserApiPackerDriverGroupWRes())?.let{EntClientPackerDriverGroupW(it)}
    suspend fun packerDriverGroupWp(req: EntUserApiPackerDriverGroupWpReq): EntUserApiPackerDriverGroupWpRes? = net(req, EntUserApiPackerDriverGroupWpRes())
    suspend fun packerDriverManagement(req: EntUserApiPackerDriverGroupDriverWReq): EntClientPackerDriverGroupDriver? = net(req, EntUserApiPackerDriverGroupDriverWRes())?.let{ EntClientPackerDriverGroupDriver(it) }
    suspend fun packerDriverGroupDriverWp(req: EntUserApiPackerDriverGroupDriverWpReq): EntUserApiPackerDriverGroupDriverWpRes? = net(req, EntUserApiPackerDriverGroupDriverWpRes())
    suspend fun packerDriverGroupDriverHideEp(req: EntUserApiPackerDriverGroupDriverHideEpReq): EntUserApiPackerDriverGroupDriverHideEpRes? = net(req, EntUserApiPackerDriverGroupDriverHideEpRes())
    //배송사
    suspend fun carrierDriverGroupList(req: EntUserApiCarrierDriverGroupReq): EntClientCarrierDriverGroup? = net(req, EntUserApiCarrierDriverGroupRes())?.let { EntClientCarrierDriverGroup(it) }
    suspend fun carrierDriverGroupHideEp(req: EntUserApiCarrierDriverGroupEpReq):EntUserApiCarrierDriverGroupEpRes? = net(req, EntUserApiCarrierDriverGroupEpRes())
    suspend fun carrierDriverGroupW(req: EntUserApiCarrierDriverGroupWReq): EntClientCarrierDriverGroupW? = net(req, EntUserApiCarrierDriverGroupWRes())?.let{ EntClientCarrierDriverGroupW(it) }
    suspend fun carrierDriverGroupWp(req: EntUserApiCarrierDriverGroupWpReq): EntUserApiCarrierDriverGroupWpRes? = net(req, EntUserApiCarrierDriverGroupWpRes())
    suspend fun carrierDriverManagement(req: EntUserApiCarrierDriverGroupDriverWReq): EntClientCarrierDriverGroupDriver? = net(req, EntUserApiCarrierDriverGroupDriverWRes())?.let{ EntClientCarrierDriverGroupDriver(it) }
    suspend fun carrierDriverGroupDriverWp(req: EntUserApiCarrierDriverGroupDriverWpReq): EntUserApiCarrierDriverGroupDriverWpRes? = net(req, EntUserApiCarrierDriverGroupDriverWpRes())
    suspend fun carrierDriverGroupDriverHideEp(req: EntUserApiCarrierDriverGroupDriverHideEpReq): EntUserApiCarrierDriverGroupDriverHideEpRes? = net(req, EntUserApiCarrierDriverGroupDriverHideEpRes())
    suspend fun carrierE(req: EntUserApiCarrierEReq): EntClientCarrierInfo? = net(req, EntUserApiCarrierERes())?.let { EntClientCarrierInfo(it) }
    suspend fun carrierEp(req: EntUserApiCarrierEpReq): EntUserApiCarrierEpRes? = net(req, EntUserApiCarrierEpRes())
    suspend fun carrierDeliveryCancel(req:EntUserApiCarrierBoundxDeliveryCancelWpReq): EntUserApiCarrierBoundxDeliveryCancelWpRes? = net(req, EntUserApiCarrierBoundxDeliveryCancelWpRes())
    suspend fun carrierDeliveryComplete(req:EntUserApiCarrierBoundxDeliveryCompleteWpReq): EntUserApiCarrierBoundxDeliveryCompleteWpRes? = net(req, EntUserApiCarrierBoundxDeliveryCompleteWpRes())
    suspend fun carrierDeliveryMap(req:EntUserApiCarrierDeliveryMapReq): EntClientDispatchMap? = net(req, EntUserApiCarrierDeliveryMapRes())?.let{ EntClientDispatchMap(it) }
    suspend fun carrierDeliveryFileWp(upfile: File, req:EntUserApiCarrierBoundxDeliveryFileWpReq): EntUserApiCarrierBoundxDeliveryFileWpRes? = net(req, EntUserApiCarrierBoundxDeliveryFileWpRes()){
        it.requestTask += ReqTaskItems("upfile" to upfile)
        it.requestTask += eRequestTask.BlobFile("upfile")
    }
    suspend fun carrierDeliveryFileDp(req:EntUserApiCarrierBoundxDeliveryFileDpReq): EntUserApiCarrierBoundxDeliveryFileDpRes? = net(req, EntUserApiCarrierBoundxDeliveryFileDpRes())
    suspend fun carrierDeliveryFileList(req:EntUserApiCarrierBoundxDeliveryFileReq): EntUserApiCarrierBoundxDeliveryFileRes? = net(req, EntUserApiCarrierBoundxDeliveryFileRes())

    suspend fun sensorSessionList(req:EntUserApiPackerSensorSessionReq):EntUserApiPackerSensorSessionRes? = net(req, EntUserApiPackerSensorSessionRes())
    suspend fun sensorSessionDetail(req:EntUserApiPackerSensorSessionVReq):EntUserApiPackerSensorSessionVRes? = net(req, EntUserApiPackerSensorSessionVRes())

    suspend fun itemPackageList(req:EntUserApiPackerItemPackageAllReq):EntUserApiPackerItemPackageAllRes? = net(req, EntUserApiPackerItemPackageAllRes())
    suspend fun invoiceCompanyList(req:EntUserApiPackerInvoiceCompanyAllReq):EntUserApiPackerInvoiceCompanyAllRes? = net(req, EntUserApiPackerInvoiceCompanyAllRes())
    suspend fun sensorPdf(req:EntUserApiSensorPdfReq):EntUserApiSensorPdfRes? = net(req, EntUserApiSensorPdfRes())

    suspend fun ltGogoXW(req:EntUserApiGogoXReq):EntUserApiGogoXRes? = net(req, EntUserApiGogoXRes())
    suspend fun ltGogoXVehicleList(req:EntUserApiVehicleList): EntUserApiCompanyListRes? = net(req, EntUserApiCompanyListRes())
    suspend fun ltGogoXPriceSearch(req: EntUserApiPriceSearchReq): EntUserApiPriceSearchRes? = net(req, EntUserApiPriceSearchRes()) {
        it.requestTask += eRequestTask.ReadTimeOut(15000)
    }
    suspend fun ltGogoXOrderSubmit(req: EntUserApiOrderSubmitReq): EntUserApiOrderSubmitRes? = net(req, EntUserApiOrderSubmitRes())
    suspend fun ltGogoXOrderSearch(req: EntUserApiOrderSearchReq): EntUserApiOrderSearchRes? = net(req, EntUserApiOrderSearchRes())
    suspend fun ltGogoXDeliveryChageStatusToNoDispatched(req: EntUserApiOrderToNoDispatchedReq): EntUserApiOrderToNoDispatchedRes? = net(req, EntUserApiOrderToNoDispatchedRes())
    suspend fun ltGogoXOrderSubmitCancle(req: EntUserApiOrderSubmitCancelReq): EntUserApiOrderSubmitCancelRes? = net(req, EntUserApiOrderSubmitCancelRes())
    suspend fun ltGogoXOrderCancel(req: EntUserApiOrderCancelReq): EntUserApiOrderCancelRes? = net(req, EntUserApiOrderCancelRes())
}
